import React, { Component } from "react";
import { connect } from 'react-redux'

import {Dropdown} from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Scrollbar from 'react-scrollbars-custom';

import { ReactComponent as DeleteImg} from '../img/ic_delete.svg'
import { ReactComponent as DotsImg} from '../img/ic_3_dots_un.svg'

import '../css/WatchesBrowser.scss';

import {
  setQuery,
  onShowQueryForm,
  removeWatch
} from '../redux/actionsProject'

class WatchesBrowser extends Component {
    constructorName = "WatchesBrowser"
    
    
  render(show) {
    var that = this
    var maxChars = 400;
    var watchesNum = (this.props.projects.currentProject && this.props.projects.currentProject.watches) ? this.props.projects.currentProject.watches.length  : 0
    if (watchesNum > 0)
        var watchQueries = this.props.projects.currentProject.watches.map(function (fd) {
            var d = fd.query
            var miPanels = null
            if ((d.searchQuery) && (d.searchQuery.selectionMust) && (d.searchQuery.selectionMust.length > 0)) 
                miPanels = d.searchQuery.selectionMust.map ((dd)=>{return <span key={"mi" + d + dd} className="panelMI selectionPanel">{dd}</span>})
            var mePanels = null
            if ((d.searchQuery) && (d.searchQuery.selectionExclude) && (d.searchQuery.selectionExclude.length > 0)) 
                mePanels = d.searchQuery.selectionExclude.map ((dd)=>{return <span key={"mi" + d + dd} className="panelME selectionPanel">{dd}</span>})
            return <Col key={"watch" + d.id} md={12} onClick={(e) => { 
                                that.props.dispatch (setQuery(d.searchQuery));
                                that.props.dispatch (onShowQueryForm(true)) 
                                }}>
                        <div className="watchQuery">
                            <Dropdown drop={"down"} className="omContainer"  onClick={(e)=>{e.stopPropagation()}}>
                            <Dropdown.Toggle variant="light" className="lowPadding">
                                <DotsImg className="dots-alone"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight="true" flip={false} className="optionsMenu withShadow">
                                    <button className="deleteBtn" onClick={
                                            (e) => 
                                                { 
                                                if (window.confirm('Are you sure you wish to remove this alert? This will influence all project members')) 
                                                    that.props.dispatch(removeWatch(fd.id, that.props.projects.currentProject.id))
                                                }
                                                }><DeleteImg/>Remove</button>
                            </Dropdown.Menu>
                        </Dropdown>
                            <p className="created">{d.created}</p>
                            <p>
                                {((d.searchQuery) && (d.searchQuery.query)) ? (d.searchQuery.query.length > maxChars ? d.searchQuery.query.substring(0,maxChars) + '...' : d.searchQuery.query) : ( '')}
                            </p>
                            {miPanels ? <p><label className="plabel">Must include:</label> 
                                            {miPanels}</p> : null}
                            {mePanels ? <p><label className="plabel">Must exclude:</label> 
                                            {mePanels}</p> : null}
                        </div>
                    </Col>
            
        })  
        
    return (
      <div className='watchesBrowser' >
            <Scrollbar noScrollX={true}>
                    <Container fluid className="stdPadded stdTopPadded">
                        <Row className="watchesPanel">
                            {watchesNum > 0 ? 
                                <Col md={12} className="watchQueries">
                                    <div className="queries">
                                        <div>
                                            {watchQueries}
                                        </div>
                                    </div>
                                </Col> : 
                                <Col md={12} className="pt-3">
                                    <h4>When you add search requests to alerts, you will see them here</h4>
                                </Col>
                            }
                            
                        </Row>
                    </Container>                 
            </Scrollbar>

        </div>
    );
  }


}

function mapStateToProps(state) {
  return {
    show: state.globalInterface.queryFormVisible,
    query: state.query,
    qsdocs:state.projects.qsdocs,
    projects:state.projects,
    interface: state.globalInterface,
    params: state.projects.company
  }
}

export default connect(mapStateToProps)(WatchesBrowser)
