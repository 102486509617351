import React from "react";
import lConfig from '../config/config'


export const localConfig = {
    ONBOARDING: [
        {'header':'EisphorIA Legal & Tax Data Intelligence', 'text':"This library is articulated by 'new-gen' search functions and interactive/collaborative functions dedicated to facilitate research. <br/><br/>Please take a few seconds to discover those."},
        {'header':'Contextual search', 'text': 'Search classically with keywords or get better results by providing even more details to the platform: type it, copy-paste it, select from any document.'},
        {'header':'Advanced results', 'text': "Results are classified by their 'contextual' proximity with your search request."},
        {'header':'Project organization', 'text': "You can create files and organize your work optimally not to repeat things and save your work easily overtime. Moreover you can directly share it with other researchers."},
        {'header':'More information', 'text': 'Use the small helper icons with question marks to discover more hints.<br/><br/>Or contact us directly at <a href="support@eisphoria.com">support@eisphoria.com</a>.<br/><br/>It is always a pleasure to help you.'},
    
    ],


    JOYRIDE: {
        options: {
              arrowColor: '#215fca',
              backgroundColor: '#215fca',
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: '#104ca1',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#fff',
              width: undefined,
              zIndex: 35000,
            },


        tutorialSteps:{ 
            "ProjectSelector": [
		            {
                      target: 'div.nheader svg',
                      content: <div><h4>From this page, you can start searching right away or create a project to organize your work</h4></div>,
                      placement: 'bottom',
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ProjectSelector':1},
                      isAction: false
                    },                    
		            {
                      target: 'div.qs > span',
                      content: <div><h4>Start searching now and switch to a specific project later</h4></div>,
                      placement: 'bottom',
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ProjectSelector':2},
                      isAction: false
                    },
                    {
                      target: '.newProjectCard',
                      content: <div><h4>Click on "Create a new project" to organize your work efficiently.</h4></div>,
                      placement: 'right',
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ProjectSelector':3},
                      isAction: true
                    },
                    {
                        target: '.myProjectCard:nth-child(4)',
                        content: <div><h4>Click on the project card to start searching</h4></div>,
                        placement: 'bottom',
                        offset: 0,
                        disableBeacon: true,
                        floaterProps: { disableAnimation: true},
                        nextStepRule: {'ProjectSelector':-1},
                        isAction: true
                    }
                  ],
            "ProjectProperties": [
                    {
                      target: '.inputProjectName',
                      content: <div><h4>Provide a project name and a description</h4></div>,
                      placement: 'top',
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ProjectProperties':1},
                      isAction: true
                    },
                    {
                      target: '.inputProjectSave',
                      content: <div><h4>Click on "Create" to save your project</h4></div>,
                      placement: 'top',
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ProjectProperties':-1},
                      isAction: true
                    }
                  ],
            "ProjectShare": [
                    {
                      target: '.addUserLine',
                      content: <div><h4>You can invite your colleagues to the project</h4>They can work together with you.</div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ProjectShare':-1},
                      isAction: false
                    },
		            
                  ],
            "QueryForm": [
                    {
                      target: '.queryContainer textarea',
                      content: <div><h4>Type your search in the box {lConfig.LANGUAGE_INVITATION}</h4>Use keywords or natural language (sentences, paragraphs,…).</div>,
                      param: "",
                      placement: 'left',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'QueryForm':1},
                      isAction: true
                    },
                    {
                        target: '.filtersPanel',
                        content: <div><h4>Filters are at your disposal if you want to explore a sub set of the documentation</h4>Documents not matching selected filters will be replaced by those which do</div>,
                        placement: 'bottom',
                        offset: 0,
                        disableBeacon: true,
                        floaterProps: { disableAnimation: true},
                        nextStepRule: {'QueryForm':2},
                        isAction: false
                    },
                    {
                      target: '.mustPanel input',
                      content: <div><h4>You can add keywords and their combinations as a "Must Include"</h4><h4><br/>"Must include" forces all the documents to contain such items. </h4></div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'QueryForm':3},
                      isAction: false
                    },
		            {
                      target: '.queryContainer .button',
                      content: <div><h4>Click on "Search" to launch your request</h4></div>,
                      placement: 'top',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'QueryForm':4},
                      isAction: true
                    },
                    {
                        target: '.addToWatchBtn',
                        content: <div><h4>Save this request to watch to be notified of new relevant documents uploaded</h4></div>,
                        placement: 'top',
                        offset: 0,
                        disableBeacon: true,
                        floaterProps: { disableAnimation: true},
                        nextStepRule: {'QueryForm':-1},
                        isAction: false
                      },
                    
                  ],
            "BrowserApp": [
                    {
                      target: '.relevancy',
                      content: <div><h4>Orange bars indicate the level of relevance</h4><h4>In the timeline view it is shown by the size of the bullet</h4></div>,
                      placement: 'right',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'BrowserApp':1},
                      isAction: false
                    },
                    {
                      target: '.gvButton',
                      content: <div><h4>Collect together the documents which are highly similar between each other. Shown as a pile in the results list and you can always consult all of them using the "Similar" button in the header of the document.</h4></div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'BrowserApp':2},
                      isAction: false
                    },
                    {
                      target: '.sourceDropdown',
                      content: <div><h4>Select the specific source or group together all the sources with the Combined view.</h4></div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'BrowserApp':-1},
                      isAction: false
                    },
                    
                ],
            "DocViewer": [
                    {
                      target: '.saveDeleteBtn',
                      content: <div><h4>Documents can be saved to the project if relevant</h4>All team members involved in this project will have access to them.</div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':1},
                      isAction: false
                    },
 		            {
                      target: '.saveAsBtn',
                      content: <div><h4>Documents can also be saved to other projects</h4></div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':2},
                      isAction: false
                    },
                    {
                      target: '.srcBtn',
                      content: <div><h4>Original documents are always accessible</h4>In their original format</div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':3},
                      isAction: false
                    },
 		            {
                      target: '.annotation-buttons button.green',
                      content: <div><h4>You can flag a document as green, yellow, red and provide an overall comment</h4></div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':4},
                      isAction: false
                    },
                    {
                      target: '.docViewer .trackY',
                      content: <div><h4>Our "heat map" indicates the most relevant sections of the document related to your request</h4>More relevant parts have a darker blue color</div>,
                      placement: 'left',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':5},
                      isAction: false
                    },
                    {
                      target: '.pnButton',
                      content: <div><h4>Click on right (and left) arrows to move to the next relevant documents</h4>You can also use your arrow keys on your keyboard to navigate left or right</div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':6},
                      isAction: true
                    },
                    {
                      target: '.textContent .contents',
                      content: <div><h4>Select part of the text with your mouse and new options will show up</h4></div>,
                      placement: 'left',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':7},
                      isAction: true
                    },
                    {
                      target: '.searchWithBtn',
                      content: <div><h4>These buttons open more possibilities:</h4>
                                    <ul>
                                        <li>"Search" to pre-fill a new search request with the selected text</li>
                                        <li>Copy the selected text to the clipboard</li>
                                        <li>Highlight a relevant section for later use with the project team.</li>
                                        <li>Comment a relevant section to discuss with the project team.</li>
                                    </ul>
                                    <h4>Click on "Search" to try</h4>
                                </div>,
                      placement: 'left',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'DocViewer':-1, 'QueryForm':5},
                      isAction: true
                    },
                ],
            "NavHeader": [
            
                ],
            "SavedDocsBrowser": [
                    {
                      target: '.downloadProjectBtn',
                      content: <div><h4>You can select documents you want to export to Word</h4>Comments and highlights will also be visible in Word.</div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'SavedDocsBrowser':-1, "NavHeader":2},
                      isAction: false
                    },
                ],
            "ActivityBrowser": [
                    {
                      target: '.logCard',
                      content: <div><h4>All actions made into the project are listed here, for instance from here you can retrieve a previous search requet made by a project member by clicking on it</h4></div>,
                      placement: 'bottom',
                      offset: 0,
                      disableBeacon: true,
                      floaterProps: { disableAnimation: true},
                      nextStepRule: {'ActivityBrowser':-1, 'NavHeader':3},
                      isAction: false
                    },
                ]
        }
    } 
};

export default localConfig