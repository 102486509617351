import React, { Component } from "react";
import { connect } from 'react-redux'
import Scrollbar from 'react-scrollbars-custom';
import Avatar from 'react-avatar';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'
  
import globalConfig from '../configGlobal/config'


import '../css/ProjectShare.scss';


import {
  setProjectShare,
  setSharingProject,
  setEditingProject
} from '../redux/actionsProjects'

import  {
    alterCurrentProjectEditor
} from "../redux/actionsProject"


class ProjectShare extends Component {
    constructorName = "ProjectShare"
    state = { downComponent: null}

    captureMouseDown = (e) => {
        e.stopPropagation()
        this.setState ({downComponent:e.target})   
    }

    captureMouseUp = (e, withClose = false) => {
        e.stopPropagation()
        if ((e.target === this.state.downComponent) && (withClose))
            this.props.dispatch (setSharingProject(null))
    }

  render(show) {
    if ((this.props.project === null) || (this.props.project === undefined))
      return null

    
    var that = this

    var heading = <h3 style={{margin:"20px"}}>You registered as a single user. Currently we allow the sharing of documents only within corporate subscriptions. Contact us if you want to upgrade</h3>
    var editors, other_users;
    if (this.props.company.company !== null)
    {
        this.props.project.editors.sort((a,b) => (a.login < b.login) ? -1 : 1);

        heading = <h4>Current editors:</h4>
        editors = this.props.project.editors.map(function (d) {
            if (d.login !== that.props.username)
                return <div key={d.upid} onClick={(e) => {e.stopPropagation(); if (that.props.currentOpenProject && (that.props.project.id === that.props.currentOpenProject.id)) { that.props.dispatch (alterCurrentProjectEditor (d.upid, d.login, false))}; that.props.dispatch (setProjectShare (that.props.project.id, d.upid, false)) }} className="userLine existing">
                    <button className="lowPadding">Remove</button>
                    <Avatar name={d.login.split('@')[0].split('.').join(' ')} size={34} round={true}/>
                    <span>{d.login}</span>  
                    </div>
            else
                return <div key={d.upid} onClick={(e) => {e.stopPropagation(); }} className="self userLine existing">
                        <Avatar name={d.login.split('@')[0].split('.').join(' ')} size={34} round={true}/>
                        <span>{d.login}</span>  
                    </div>
      })

      var editors_ids = this.props.project.editors.map(function (d) { return d.upid })
      var o_users = this.props.company.users.filter (function (d) { return !editors_ids.includes (d.upid) })
      if (o_users.length > 0)
      {
        o_users.sort((a,b) => (a.login < b.login) ? -1 : 1);
        var other_users_items = o_users.map(function (d) { 
          return <div key={d.upid} className="userLine addUserLine" onClick={(e) => {e.stopPropagation(); 
                                                                                        that.props.dispatch (setProjectShare (that.props.project.id, d.upid, true)); 
                                                                                        if (that.props.currentOpenProject && (that.props.project.id === that.props.currentOpenProject.id)) { that.props.dispatch (alterCurrentProjectEditor (d.upid, d.login, true))};
                                                                                        if (that.constructorName in that.props.interface.tutorialActiveElements) { that.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[that.constructorName][that.props.interface.tutorialActiveElements[that.constructorName]].nextStepRule))} }}>
                    <button className="lowPadding addUserToProjectBtn" >+ Add</button>
                    <Avatar name={d.login.split('@')[0].split('.').join(' ')} size={34} round={true}/>
                    <span>{d.login}</span>
                  
                </div>
        }) 
        other_users = <div>
                      <h4>Other users of your company:</h4>
                      {other_users_items}
                  </div> 
      }
    
    }

    
    
    return (
      <div className="ProjectShare fullSize" onMouseDown={this.captureMouseDown} onMouseUp={(e) => {this.captureMouseUp(e, true)}}>
            <div className="contents" onMouseDown={this.captureMouseDown} onMouseUp={this.captureMouseUp}>
                { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                    <Joyride
                        ref="highZ"
                        tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                        styles={{options: Object.assign({}, globalConfig.JOYRIDE.options, { zIndex:100000 })}}
                        disableCloseOnEsc={true}
                        disableOverlayClose={true}
                        disableOverlay={true}
                        disableScrolling={true}
                        showSkipButton={true}
                        hideBackButton={true}
                        run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                        locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                        continuous={false}
                        stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                        callback = { data => {
                                const { action, type } = data;
                                if (action === ACTIONS.SKIP)
                                    this.props.dispatch (setTutorialElements(null))
                                if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                    this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                
                            }
                        }
                    />
                    :null
                }
                <button className="outerCloseBtn" onClick={(e) => {e.stopPropagation (); this.props.dispatch (setSharingProject(null))}}>Back</button>
                <span className="captionSpan">
                    <button className="tabBtn" onClick={(e) => {e.stopPropagation(); this.props.dispatch(setEditingProject(this.props.project)); this.props.dispatch(setSharingProject(null)); if (that.constructorName in that.props.interface.tutorialActiveElements) { that.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[that.constructorName][that.props.interface.tutorialActiveElements[that.constructorName]].nextStepRule))}}}><h3>Project details</h3></button>
                    <h3 className="caption">Share</h3>
                </span>
                
                <h3 className="projectName">{this.props.project.name}</h3>
                <div className="scrollContainer">
                    <Scrollbar noScrollX={true}>
                    
                        {heading}
                        {editors}
                        {other_users}
                    </Scrollbar>
                </div>
                <div className="submitContainer">
                    <button className="stdBtn continueBtn" type="submit" onClick={(e) => {e.stopPropagation (); this.props.dispatch (setSharingProject(null))}}>Close</button>
                </div> 
            
            </div>
        
        
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.projects.company,
    username: state.authParams.username,
    project: state.projects.sharingProject,
    interface: state.globalInterface,
    currentOpenProject: state.projects.currentProject
  }
}

export default connect(mapStateToProps)(ProjectShare)


