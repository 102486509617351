import React, { Component } from 'react';
import { connect } from 'react-redux'

import '../css/Onboarding.scss';
import localConfig from '../configGlobal/config.js'

import {
    showOnboarding
  } from '../redux/actionsProjects'
  

class Onboarding extends Component
{
    state = {
      currentSlide: 0,
    }

    handleSkip = e => {
        this.props.dispatch(showOnboarding(false))
      };

    render() {
        
        if (this.props.onboardingDismissed)
            return null;

        
        var slides= localConfig.ONBOARDING
        var slide = slides[this.state.currentSlide]
        
        let counters = [];
        [...Array(slides.length)].map((e, i) => {
                if (i===this.state.currentSlide)
                    counters.push(<span className="counter active" key={i}></span>);
                else
                    counters.push(<span className="counter" key={i}></span>); 
                return null})

        

        return (
            <div className="onboarding">
                <div className="slides">
                    <div className="slide">
                        <button className="skipButton" onClick={this.handleSkip}>x</button>
                        <div className="contentsContainer"><div className="contents alignCenter">
                            <h3>{slide.header}</h3>
                            <p dangerouslySetInnerHTML={{ __html: slide.text }} className="sub"></p>
                            <div className="controlButtons">
                                {(this.state.currentSlide === slides.length - 1) ? 
                                    <button onClick={e=>{e.stopPropagation(); this.handleSkip();}}>Start using</button>
                                    :
                                    <button onClick={e=>{this.setState({'currentSlide':this.state.currentSlide+1})}}>Continue</button>
                                    }
                            </div>              
                        </div></div>
                    </div>
                    <div className="counters">{counters}</div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
      onboardingDismissed: state.globalInterface.onboardingDismissed,
    }
}
  
export default connect(mapStateToProps)(Onboarding)
