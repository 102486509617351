import React, { Component } from 'react';
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import zxcvbn from 'zxcvbn'

import '../css/NavHeader.scss';
import logo from '../img/LogoE.png'
import imec from '../img/imec.svg'
import promoImg from '../img/promoImg.png'



import '../css/LoginForm.scss';

import {
    auth,
    register,
    forgetPass,
    setLoginScreenType
  } from '../redux/actionsAuth'
  


class LoginForm extends Component
{
    state = {
        username:"",
        password:"",
        name:"",
        surname: "",
        pScore: {score:0}
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
        
      };

    handleLoginSubmit = e => {
        e.preventDefault(); 
        
        if (this.props.loginScreenType === 'login')
            this.props.dispatch(auth (this.state.username, this.state.password))
        else
            this.props.dispatch(forgetPass (this.state.username))
        
    }

    handleRegisterSubmit = (e)=>{
        e.preventDefault(); 
        this.props.dispatch(register ({mail:this.state.username, password:this.state.password, name:this.state.name, surname:this.state.surname, subscribed:this.state.subscribed}))
    }
    
    render() {
        const mailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var fPassFilled = (this.state.username) && (mailRegExp.test(this.state.username))
        var loginFilled = fPassFilled && this.state.password && this.state.password.length > 3
        
        var registerFilled = (this.state.pScore.score > 2) && loginFilled && this.state.termsAccepted &&
                            this.state.name && (this.state.name.length > 1) &&
                            this.state.surname && (this.state.surname.length > 1)

        
        return (
            <div className={"login " + this.props.loginScreenType}>
                <Navbar bg="light" expand="lg">
                    <Container fluid className="stdPadded">
                        <Row>
                            <Col md={3}>
                                <Navbar.Brand href="https://www.eisphoria.com"><img src={logo} className="logo" alt="logo" /></Navbar.Brand>
                            </Col>
                            <Col md={9} className="align-right">
                                {this.props.loginScreenType === 'register' ? 
                                    <h4>Already have an account? <button className="textLikeBtn" onClick={(e)=>{e.stopPropagation(); this.props.dispatch (setLoginScreenType('login'))}}>Log In →</button></h4>
                                    : <h4>No account yet? <button className="textLikeBtn" onClick={(e)=>{e.stopPropagation(); this.props.dispatch (setLoginScreenType('register'))}}>Register →</button></h4>
                                }
                            </Col>
                            </Row>
                    </Container>    
                </Navbar>
                <div className="loginForm">
                    {this.props.loginScreenType === 'register' ? 
                        <div className="contentsContainer"><div className="contents">
                            <Container fluid>
                                <Row>
                                    <Col md={5} className="leftPart">
                                        <img src={promoImg} alt="promo" />
                                        <div className="vspace"></div>
                                        <h3>Your fast lane to critical information</h3>
                                        <ul>
                                            <li><div><span className="h">Save time</span><span className="l">EisphorIA helps you search more efficiently with keywords or with natural language</span></div></li>
                                            <li><div><span className="h">Start working right way</span><span className="l">Our tool fully digitizes, analyzes and organizes your data set in a few hours only!</span></div></li>
                                            <li><div><span className="h">Don't worry about technology</span><span className="l">Yes, we use top notch AI and it works as it should: it works for you and not vice-versa!</span></div></li>
                                        </ul>
                                    </Col>
                                    <Col md={7} className="rightPart">
                                        <h3>Register at EisphorIA</h3>
                                        <p className="sub">EisphorIA is designed just and only for lawyers. Using your professional e-mail will expedite the registration process</p>
                                        
                                        <form method="post" onSubmit={this.handleRegisterSubmit}>
                                            <div className="field">
                                                <div className="control withFloatingLabel">
                                                    <input
                                                        className="input"
                                                        name="name"
                                                        required
                                                        onChange={this.handleInputChange}
                                                        value={this.state.name}
                                                    />
                                                    <span className="floating-label">First name</span>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <div className="control withFloatingLabel">
                                                    <input
                                                        className="input"
                                                        name="surname"
                                                        required
                                                        onChange={this.handleInputChange}
                                                        value={this.state.surname}
                                                    />
                                                    <span className="floating-label">Last name</span>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <div className="control withFloatingLabel">
                                                    <input
                                                        className="input"
                                                        name="username"
                                                        required
                                                        onChange={this.handleInputChange}
                                                        value={this.state.username}
                                                    />
                                                    <span className="floating-label">Email</span>
                                                </div>
                                            </div>
                                            <div className="vspace"></div>  
                                            <div className="field">
                                                <div className="control withFloatingLabel">
                                                    <input
                                                        className="input"
                                                        name="password"
                                                        required
                                                        onChange={this.handleInputChange}
                                                        value={this.state.password}
                                                        type="password"
                                                    />
                                                    <span className="floating-label">Password</span>
                                                    <span className={"passwordStrength ps" + this.state.pScore.score}><span className="p1"><span/></span><span className="p2"><span/></span><span className="p3"><span/></span><span className="p4"><span/></span></span>
                                                    {this.state.pScore.feedback && this.state.pScore.feedback.warning ? <span className="passwordHint">{this.state.pScore.feedback.warning}</span> : null}
                                                </div>
                                            </div>

                                            <div className="field">
                                                <div className="control chControl">
                                                    <input type="checkbox" onChange={(e) => { this.setState ({termsAccepted: e.target.checked})}}></input>
                                                    <span>I have read and agree to the EisphorIA <a href="https://www.eisphoria.com/policies/terms/" target="_blank" rel="noreferrer noopener">Terms and conditions</a></span>
                                                </div>
                                            </div>

                                            <div className="field">
                                                <div className="control chControl">
                                                    <input type="checkbox" onChange={(e) => { this.setState ({subscribed: e.target.checked})}}></input>
                                                    <span>I accept to be contacted directly or via the newsletter (we promise to send you only a valuable information)</span>
                                                </div>
                                            </div>

                                            <div className="vspace"></div>  
                                            <div className="control">
                                                <button className={"button is-info" + (registerFilled ? "" : " disabled")} disabled={!registerFilled}>Register</button>
                                            </div>
                                        </form>

                                        <div style={{display:"none"}}>
                                            <img className="imecLogo" alt="imecLogo" src={imec}/>
                                            <p>Trusted by <a href="https://www.imec-int.com/en/istart">imec.istart</a></p>
                                        </div>

                                        
                                        
                                    </Col>
                                </Row>
                            </Container>
                                
                            
                        </div></div>
                        :
                        <div className="contentsContainer"><div className="contents">
                            <h3>Login to EisphorIA</h3>
                            <div className="vspace"></div>
                            
                            
                            <div>
                                <form method="post" onSubmit={this.handleLoginSubmit}>
                                    <div className="field">
                                        <div className="control withFloatingLabel">
                                            <input
                                                className="input"
                                                name="username"
                                                required
                                                onChange={this.handleInputChange}
                                                value={this.state.username}
                                            />
                                            <span className="floating-label">Email</span>
                                        </div>
                                    </div>
                                    
                                    {this.props.loginScreenType === 'login' ? 
                                        <div className="field">
                                            <div className="control withFloatingLabel">
                                                <input
                                                    className="input"
                                                    name="password"
                                                    required
                                                    onChange={this.handleInputChange}
                                                    value={this.state.password}
                                                    type="password"
                                                />
                                                <span className="floating-label">Password</span>
                                            </div>
                                        </div>:null
                                    }

                                    {this.props.loginScreenType === 'login' ? 
                                        <button type="button" className="forgotPassword textLikeBtn mr-0 px-0 mt-0"  onClick={(e)=>{e.stopPropagation(); this.props.dispatch (setLoginScreenType('forget'))}}>Forgot password?</button>
                                        :null}
                                        
                                    <div className="control">
                                        { this.props.loginScreenType === 'login' ?
                                            <button type="submit"   className={"button is-info" + (loginFilled ? "" : " disabled")} disabled={!loginFilled} >Login</button>
                                            : <button type="submit"  className={"button is-info" + (fPassFilled ? "" : " disabled")} disabled={!fPassFilled} >Send reset link</button> }
                                    </div>

                                    {this.props.loginScreenType === 'login' ? 
                                        <div className="noAcc">
                                            <button  className={"button is-info toRegister"} onClick={(e)=>{e.stopPropagation(); this.props.dispatch (setLoginScreenType('register'))}}>Register</button>
                                        </div>:
                                        <button type="button" className="backToLogin textLikeBtn px-0"  onClick={(e)=>{e.stopPropagation(); this.props.dispatch (setLoginScreenType ('login'))}}>Back to login</button>
                                    }

                                    



                                </form>

                             
                            </div>
                        </div></div>
                    }


                
                </div>
            </div>
            
        );
    }

}

function mapStateToProps(state) {
    return {
        loading: state.globalInterface.loading,
        loginScreenType: state.globalInterface.loginScreenType
        
    }
}

export default connect(mapStateToProps)(LoginForm)
