import React, { Component } from 'react';
import { connect } from 'react-redux'

import '../css/Notification.scss';

import {
    setNotification
} from '../redux/actions'


class Notification extends Component
{
    render () {
        if ((!this.props.interface.notificationHeader) && ((!this.props.interface.notificationContent)))
            return null
        
        return (
            <div className="notification">
                {this.props.interface.notificationType === 'bottom' ?
                    <div className={"bottom " + this.props.interface.notificationClass}>
                        <div className="deleteSign" onClick={
                        (e) => 
                        { 
                            e.stopPropagation(); 
                            this.props.dispatch (setNotification());
                        }}></div>
                        {this.props.interface.notificationHeader ? <h4>{this.props.interface.notificationHeader}</h4> : null}
                        {this.props.interface.notificationContent ? <span>{this.props.interface.notificationContent}</span> : null}
                    </div>  
                    : null
                }

                {this.props.interface.notificationType === 'popup' ?
                    <div className={"popupContainer " + this.props.interface.notificationClass}>
                        <div className="popup">
                            {this.props.interface.notificationHeader ? <h3>{this.props.interface.notificationHeader}</h3> : null}
                            {this.props.interface.notificationContent ? <span>{this.props.interface.notificationContent}</span> : null}
                            <button className="closeBtn" onClick={(e)=>{this.props.dispatch (setNotification());}}>Close</button>
                        </div>
                    </div>  
                    : null
                }
            </div>
            
        );
    }
}


function mapStateToProps(state) {
    return {
        interface: state.globalInterface,
        
    }
}

export default connect(mapStateToProps)(Notification)
