import React, { Component } from "react";
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom';
import StringUtils from "../utils/StringUtils";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Dropdown} from "react-bootstrap";


import Avatar from 'react-avatar';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import GHelper from './GHelper'
import JoyrideTooltip from './JoyrideTooltip';
import {
    setTutorialElements
} from '../redux/actions'
  
import globalConfig from '../configGlobal/config'

 


import '../css/ProjectSelector.scss';

import { ReactComponent as SearchImg} from '../img/ic_search_active.svg'


import { ReactComponent as DeleteImg} from '../img/ic_delete.svg'
import { ReactComponent as ShareImgSmall} from '../img/ic_share_small.svg'
import { ReactComponent as EditImg} from '../img/ic_edit.svg'
import { ReactComponent as ArchiveImg} from '../img/ic_archive.svg'

import { ReactComponent as NewProjectImg} from '../img/ic_add_project.svg'
import { ReactComponent as SortImg} from '../img/ic_sort.svg'

import { ReactComponent as DotsImg} from '../img/ic_3_dots_un.svg'

import { ReactComponent as ImgFlag} from "../img/ic_card-flag.svg";
import { ReactComponent as ImgHighlight} from "../img/ic_card-highlight.svg";
import { ReactComponent as ImgComment} from "../img/ic_card-comment.svg";
import { ReactComponent as ImgSavedDocs} from "../img/ic_card-savedfolder.svg";


import {
  getProjects,
  deleteProject,
  alterArchiveProject,
  setSharingProject,
  setEditingProject
} from '../redux/actionsProjects'

import {
  onShowQueryForm,
  setQuery,
  onProjectParamsLoaded,
  onSearchResultsLoaded,
  setLoadedDoc,
  setBrowsingDoc
} from '../redux/actionsProject'

import {
    setURL
} from '../redux/actions'



class ProjectSelector extends Component {
    constructorName = 'ProjectSelector'

    state = {
        projectName: "",
        selectedProjectId: -1,
        selectedSaved: false,
        shownOptionsPanelID: null,
        sortByMenuShown: false,
        showingProjects: "All",
        sortBy: "Creation date",

    };

    componentDidMount() {
        this.props.dispatch (getProjects(true))
    }

    render(show) {
        if (this.state.selectedProjectId > 0)
        {
            this.props.dispatch (setLoadedDoc (null))
            this.props.dispatch (setBrowsingDoc (null))
            this.props.dispatch (onProjectParamsLoaded({savedDocs: [],
                project: null}))
            this.props.dispatch (setQuery({query:""}));
            this.props.dispatch (onSearchResultsLoaded ({docs:[], params:null, net:[]}))
            var prjLink = "/v2/project/" + this.state.selectedProjectId + (this.state.selectedSaved ? "/saved":"")
            return <Redirect push to={prjLink} />;
        }

        if (this.props.interface.toSetURL)
        {
            var toUrl = this.props.interface.toSetURL
            this.props.dispatch (setURL (null))
            return <Redirect push to={toUrl} />
        }

        var that = this

        if (this.state.sortBy === 'Name')
            this.props.projects.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
        if (this.state.sortBy === 'Creation date')
            this.props.projects.sort((a,b) => (a.created > b.created) ? -1 : 1);
        if (this.state.sortBy === 'Saved documents')
            this.props.projects.sort((a,b) => (a.savedDocsNumber > b.savedDocsNumber) ? -1 : 1);
        if (this.state.sortBy === 'Team size')
            this.props.projects.sort((a,b) => (a.editors.length > b.editors.length) ? -1 : 1);



        var proj = this.props.projects.map(function (d) { 
            var isShown = ((that.state.showingProjects === 'All') && (!d.isArchived)) ||
                            ((that.state.showingProjects === 'My projects') && (d.isOwner) && (!d.isArchived)) ||
                            ((that.state.showingProjects === 'Shared to me') && (!d.isOwner) && (!d.isArchived)) ||
                            ((that.state.showingProjects === 'Archive') && (d.isArchived)) 
            if (d.isPersonal)
                return null

            return <Col key={'project_' + d.id} xl={4} className={isShown ? "myProjectCard" : "myProjectCard hidden"} onClick={
                (e) => 
                    { 
                        e.stopPropagation(); 
                        if (that.props.popupMode)
                        {
                            that.props.projectSelectedRoutine (d.id)
                        }
                        else
                        {
                            that.props.dispatch(onShowQueryForm(true)); 
                            that.setState ({ selectedProjectId: d.id, selectedSaved: false })
                        }
                        if (that.constructorName in that.props.interface.tutorialActiveElements) 
                            that.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[that.constructorName][3].nextStepRule))
                        }}>
                <Row>
                    <Dropdown drop={"down"} className="omContainer"  onClick={(e)=>{e.stopPropagation()}}>
                        <Dropdown.Toggle variant="light" className="lowPadding">
                            <DotsImg className="dots-alone"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight="true" flip={false} className="optionsMenu withShadow">
                                <button className="shareBtn" onClick={
                                        (e) => 
                                            { 
                                            e.stopPropagation(); 
                                            that.props.dispatch (setSharingProject(d))
                                            }}><ShareImgSmall/>Share</button>
                                {d.isOwner ? 
                                    <button className="editBtn" onClick={
                                            (e) => 
                                                { 
                                                e.stopPropagation(); 
                                                that.props.dispatch (setEditingProject(d))
                                                }}><EditImg/>Edit</button> : null
                                }
                                <button className="archiveBtn" onClick={
                                        (e) => 
                                            { 
                                            e.stopPropagation(); 
                                            that.props.dispatch(alterArchiveProject(d.id, !d.isArchived))
                                            }}><ArchiveImg/>{d.isArchived ? "Unarchive":"Archive" }</button>
                                <button className="deleteBtn" onClick={
                                        (e) => 
                                            { 
                                            e.stopPropagation(); 
                                            if (window.confirm('Are you sure you wish to unfollow this project? This cannot be undone')) 
                                                that.props.dispatch(deleteProject(d.id))
                                            }
                                            }><DeleteImg/>Unfollow</button>
                        </Dropdown.Menu>
                    </Dropdown>
                        
                    <Col lg={12}>
                        <h4 className="projectTitle clickable">{d.name} {(d.description && (d.description.length > 0)) ? <span className="qm">i<span className="withShadow">{d.description}</span></span>:null} </h4> 
                    </Col>

                    <Col className="d-none" lg={12}>
                        <Row className="fields">
                            <Col md={4}><span className="label">Documents:</span><span className="value">{d.savedDocsNumber}</span></Col>
                            <Col md={4}><span className="label">Created:</span><span className="value">{d.created}</span></Col>
                            { d.lastEdit ? <Col md={4}><span className="label">Last activity:</span><span className="value">{d.lastEdit}</span></Col> : null }
                        </Row>
                    </Col>

                    <Col lg={12}>
                        <Row className="sharedPanel">
                            <Col md={12}>
                                <div className="float-left flags" onClick={(e)=>{e.stopPropagation(); that.setState ({ selectedProjectId: d.id, selectedSaved: true })}}>
                                    { d.flagsNumber.map(function (ann) 
                                                                    { 
                                                                        return <span key={'annitem' + d.id + 'c' + ann[0]} className={"annItem flag" + StringUtils.capitalize(ann[0])}><ImgFlag /> {ann[1] > 1 ? ("" + ann[1]):null} </span>
                                                                    }) 
                                                                }
                                    { d.commentsNumber > 0 ? <span className="annItem flagNeutral"><ImgComment />{(d.commentsNumber > 1) ? ("" + d.highlightsNumber):""} </span>:null}
                                    { d.highlightsNumber > 0 ? <span className="annItem flagNeutral"><ImgHighlight />{(d.highlightsNumber > 1) ? ("" + d.highlightsNumber):""} </span>:null}
                                    { d.savedDocsNumber > 0 ? <span className="annItem flagNeutral"><ImgSavedDocs />{(d.savedDocsNumber > 1) ? ("" + d.savedDocsNumber):""} </span>:null}
                                </div>
                                <div className="alignRight">
                                    { d.editors.map(function (ed) {
                                                if (ed.login === that.props.username)
                                                    return null
                                                else
                                                    return <Avatar key={d.id + '_' + ed.login + 'av'} name={ed.login.split('@')[0].split('.').join(' ')} size={29} round={true}/>              
                                        }) }
                                </div>
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </Col>
            })  

            var personalProjectId = 0
                var personalProj = this.props.projects.map(function (d) { 
                    if (!d.isPersonal)
                        return null
                    personalProjectId = d.id
                    if ((that.state.showingProjects !== 'All') && (that.state.showingProjects !== 'My projects'))
                        return null
        
                    return <Col key={'project_' + d.id} xl={4} className="myProjectCard personal" onClick={
                        (e) => 
                            { 
                            e.stopPropagation(); 
                            that.props.dispatch(onShowQueryForm(true)); 
                            that.setState ({ selectedProjectId: d.id, selectedSaved: false })
                            }}>
                        <Row>
                            <Col lg={12}>
                                <h4 className="projectTitle clickable">{d.name} {(d.description && (d.description.length > 0)) ? <span className="qm">i<span className="withShadow">{d.description}</span></span>:null} </h4> 
                            </Col>
        
                            <Col lg={12}>
                                <div className="float-left flags" onClick={(e)=>{e.stopPropagation(); that.setState ({ selectedProjectId: d.id, selectedSaved: true })}}>
                                    { d.flagsNumber.map(function (ann) 
                                                                    { 
                                                                        return <span className={"annItem flag" + StringUtils.capitalize(ann[0])}><ImgFlag /> {ann[1] > 1 ? ("" + ann[1]):null} </span>
                                                                    }) 
                                                                }
                                    { d.commentsNumber > 0 ? <span className="annItem flagNeutral"><ImgComment />{(d.commentsNumber > 1) ? ("" + d.highlightsNumber):""} </span>:null}
                                    { d.highlightsNumber > 0 ? <span className="annItem flagNeutral"><ImgHighlight />{(d.highlightsNumber > 1) ? ("" + d.highlightsNumber):""} </span>:null}
                                    { d.savedDocsNumber > 0 ? <span className="annItem flagNeutral"><ImgSavedDocs />{(d.savedDocsNumber > 1) ? ("" + d.savedDocsNumber):""} </span>:null}
                                </div>
                                
                            </Col>
                        


                            <Col lg={12} className="d-none">
                                <Row className="fields">
                                    <Col lg={4}><span className="label">Documents:</span><span className="value">{d.savedDocsNumber}</span></Col>
                                    { d.lastEdit ? <Col md={4}><span className="label">Last activity:</span><span className="value">{d.lastEdit}</span></Col> : null }
                                </Row>
                            </Col>
        
                        </Row>
                        </Col>
                    })  

        
        if ((personalProjectId > 0) && this.props.location && this.props.location.search && this.props.location.search.includes ("toDoc="))
        {
            var td = this.props.location.search
            td = td.substring (td.indexOf ("toDoc=") + 6)
            if (td.includes ("&"))
                td = td.substring (0, td.indexOf ("&"))
            return <Redirect to={"/v2/project/" + personalProjectId + "/doc/" + td} />
        }

        return (
            <div className={"ProjectSelectorContainer"  + (this.props.popupMode ? " popupMode ":" withQS")} onClick={this.props.closeRoutine}>
                <div className="ProjectSelector" onClick={(e) => { e.stopPropagation(); this.setState({shownOptionsPanelID:null, sortByMenuShown: false})}}>
                    { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                        <Joyride
                            tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                            styles={{options: globalConfig.JOYRIDE.options}}
                            disableCloseOnEsc={true}
                            disableOverlayClose={true}
                            disableOverlay={true}
                            disableScrolling={true}
                            showSkipButton={true}
                            hideBackButton={true}
                            showProgress={true}
                            run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                            continuous={false}
                            stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                            callback = { data => {
                                    const { action, type } = data;
                                    if (action === ACTIONS.SKIP)
                                        this.props.dispatch (setTutorialElements(null))
                                    if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                        this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                }
                            }
                        />
                        :null
                    }

                    {this.props.popupMode ? 
                        <div className="popupModeHeader">
                            <span className="captionSpan">
                                <h3 className="caption">{this.props.captions.caption}</h3>
                            </span>
                            <span className="docCaptionSpan">{this.props.captions.docName}</span>
                        </div>:
                        null
                    }

                    <div className="subheader stdPadded withShadow">
                        <div className="float-right alignRight">
                            <Dropdown drop={"down"} className="filterDropdown">
                                <Dropdown.Toggle variant="light" className="">
                                    <span className="weightRegular"><SortImg/> Sort by</span> {this.state.sortBy}
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight="true" flip={false} className="optionsMenu withShadow">
                                {['Creation date', 'Saved documents', 'Team size', 'Name'].map(function (d) { return <button key={'sortBy_' + d} className={that.state.sortBy === d ? 'active' : ''} onClick={(e) => { e.stopPropagation(); that.setState ({sortBy:d, sortByMenuShown:false, shownOptionsPanelID:null})}}>{d}</button> }) }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>   
                            {['All', 'My projects', 'Shared to me', 'Archive'].map(function (d) { return <button key={'filter_projects' + d} className={that.state.showingProjects === d ? 'active' : ''} onClick={(e) => { e.stopPropagation(); that.setState ({showingProjects:d, sortByMenuShown:false, shownOptionsPanelID:null})}}>{d}</button> }) }
                        </div>
                        
                    </div>
                    <div className="fillSize" >
                        <Scrollbar noScrollX={true}>
                            <Container fluid className="stdPadded stdTopPadded">
                                <Row>
                                    <Col md={8}>
                                        <h3>Available projects</h3>
                                    </Col>
                                    
                                </Row>
                                <Row className="projectsRow">
                                    {((that.state.showingProjects === 'All') || (that.state.showingProjects === 'My projects')) ? 
                                        <Col lg={4} className="myProjectCard newProjectCard alignCenter" onClick={(e) => {e.stopPropagation(); this.props.dispatch (setEditingProject(0)); if (this.constructorName in this.props.interface.tutorialActiveElements) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][2].nextStepRule))}}}>
                                            <Row className="align-items-center h-100">
                                                <Col md={12}>
                                                    <div className="align-middle justify-content-center align-self-center">
                                                        <NewProjectImg />
                                                        <h3>Create a new project {this.props.popupMode ? "to save there": "and Start your search"}</h3>
                                                        <span>Click to continue</span>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col> : null}


                                    <Col lg={12} className="headers">
                                        <Row>
                                            <Col md={4}>Project</Col>
                                            <Col md={4}>
                                                <Row className="fields">
                                                    <Col md={2}>Files</Col>
                                                    <Col md={4}>Created</Col>
                                                    <Col md={6}>Last action</Col>
                                                </Row>
                                            </Col>
                                            <Col md={3}>
                                                <Row className="sharedPanel">
                                                    <Col md={12} className="alignRight">
                                                        Shared
                                                    </Col>                        
                                                </Row>  
                                            </Col>

                                        </Row>
                                    </Col>
                                    {!this.props.popupMode ? personalProj:null}
                                    {proj}
                                </Row>
                            </Container>                  
                        </Scrollbar>
                    </div>

                </div>
                <GHelper hParams={{'ProjectSelector':0, "ProjectProperties":0, "ProjectShare":0}}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.globalInterface.loading,
        projects: state.projects.list,
        sharingProject: state.projects.sharingProject,
        editingProject: state.projects.editingProject,
        username: state.authParams.username,
        interface: state.globalInterface
    }
}

export default connect(mapStateToProps)(ProjectSelector)


