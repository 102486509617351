import config from 'react-global-configuration'

import {
    setLoading,
    setNotification,
    getAccessToken,
} from './actions'

import {
    authRefresh,
    onLoadProfile
} from './actionsAuth'

export const MONITOR_PLATFORMS_LOADED = 'MONITOR_PLATFORMS_LOADED'
export const MONITOR_PLATFORM_DOCS_LOADED = 'MONITOR_PLATFORM_DOCS_LOADED'



export function onLoadPlatforms (platforms) {
    return {
        type: MONITOR_PLATFORMS_LOADED,
        platforms
    }
}

export function onLoadPlatformDocs (docs, platform, delay, browsingDecisions) {
    return {
        type: MONITOR_PLATFORM_DOCS_LOADED,
        docs,
        platform,
        delay,
        browsingDecisions,
    }
}

export function treatGenericResponse (response, dispatch) {
    if (response.error && (response.error.length > 0))
        dispatch(setNotification ("Error occured.", response.error))
    if (response.platforms)
        dispatch(onLoadPlatforms(response.platforms))
    if (response.docs)
        dispatch(onLoadPlatformDocs(response.docs.docs, response.platform, response.delay, response.browsingDecisions))
    
}


export function serverCommunication (endpoint, conf, expiredCallback, extraExecOnSuccess) {
    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + endpoint, conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(expiredCallback)
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if ((!result.error) && (extraExecOnSuccess))
                        if (extraExecOnSuccess === 'getFirstPlatform')
                            dispatch(getPlatformDocs (result.platforms.Europe[0].id, '14D', true))
                        else 
                        {
                            for (var toDispatch of extraExecOnSuccess)
                                dispatch(toDispatch)
                            dispatch(setLoading(false))
                        }
                    
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log (error)
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")
                    
                }
            )
    }
}

export function getPlatforms () {
    var aT = getAccessToken ()
    var cUrl = aT ? "api/monitor/platforms" : "api/monitor/anonymous/platforms"
    const conf = {
      method: "get",
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatforms", module:"actionsMonitor", params:arguments}), 
        'getFirstPlatform')
}

export function getPlatformDocs (pid, delay, decisions) {
    var aT = getAccessToken ()
    var cUrl = aT ? "api/monitor/platformDocs" : "api/monitor/anonymous/platformDocs"
    const conf = {
      method: "post",
      body: JSON.stringify({ "pid": pid, "delay":delay, "decisions": decisions }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatformDocs", module:"actionsMonitor", params:arguments}), 
        [])
}
