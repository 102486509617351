import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'

import Notification from "../interfaceParts/Notification"

import ResetPass from "../interfaceParts/ResetPass"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import {
  checkLoggedIn
} from '../redux/actionsAuth'

class ResetPassPage extends Component {
    componentWillMount() {
        this.props.dispatch(checkLoggedIn())
    }

    render() {
        if (this.props.shouldRedirectToLogin)
            return (<Redirect to='/login' />)

        if (this.props.loggedIn)
            return (<Redirect to='/' />)

        return (
            <div className="App">
                <ResetPass secKey={this.props.location.search}/>  
                <LoadingSpinner show={this.props.loading} />
                <Notification/>
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.authParams.error,
        loggedIn: state.authParams.loggedIn,
        loading: state.globalInterface.loading,
        username: state.authParams.username,
        shouldRedirectToLogin: state.authParams.shouldRedirectToLogin,
        
    }
}


export default connect(mapStateToProps)(ResetPassPage)


