import React, { Component } from "react";
import { connect } from 'react-redux'
import Scrollbar from 'react-scrollbars-custom';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'
  
import globalConfig from '../configGlobal/config'


import '../css/ProjectProperties.scss';


import {
    setEditingProject,  
    modifyProject, 
    setSharingProject 
} from '../redux/actionsProjects'


class ProjectProperties extends Component {
    constructorName = "ProjectProperties"
    state = {
        projectName: this.props.project ? this.props.project.name : "",
        projectDescription: this.props.project ? this.props.project.description : "",
        projectId: this.props.project ? (this.props.project === 0 ? null : this.props.project.id) : null,
        downComponent: null,
        keepFocus: null
      };

    captureMouseDown = (e) => {
        e.stopPropagation()
        this.setState ({downComponent:e.target})   
    }

    captureMouseUp = (e, withClose = false) => {
        e.stopPropagation()
        if ((e.target === this.state.downComponent) && (withClose))
            this.props.dispatch (setEditingProject(null))
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if ((e.target.name === "projectName") && (this.props.interface.tutorialActiveElements[this.constructorName] === 0))
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
        
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.dispatch (modifyProject(this.state.projectName, this.state.projectDescription, this.state.projectId))
        if (this.constructorName in this.props.interface.tutorialActiveElements)
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][1].nextStepRule))
    };

  render(show) {
    if ((this.props.project === null) || (this.props.project === undefined))
      return null
    
    var canEdit = !this.props.project.isPersonal && ((!this.state.projectId) || this.props.project.isOwner)
    
    return (
      <div className="ProjectProperties fullSize" onMouseDown={this.captureMouseDown} onMouseUp={(e) => {this.captureMouseUp(e, true)}}>
            <div className="contents" onMouseDown={this.captureMouseDown} onMouseUp={this.captureMouseUp}>
                { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                    <Joyride
                        tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                        styles={{options: Object.assign({}, globalConfig.JOYRIDE.options, { zIndex:50000 })}}
                        disableCloseOnEsc={true}
                        disableOverlayClose={true}
                        disableOverlay={true}
                        disableScrolling={true}
                        showSkipButton={true}
                        hideBackButton={true}
                        spotlightClicks={true}
                        run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                        locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                        continuous={false}
                        stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                        callback = { data => {
                                const { action, type } = data;
                                if (action === ACTIONS.SKIP)
                                    this.props.dispatch (setTutorialElements(null))
                                if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                    this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                
                            }
                        }
                    />
                    :null
                }
                <button className="outerCloseBtn" onClick={(e) => {e.stopPropagation (); this.props.dispatch (setEditingProject(null))}}>Back</button>
                <Scrollbar noScrollX={true}>
                    <span className="captionSpan">
                        <h3 className="caption">Project details</h3>
                        {this.state.projectId && (!this.props.project.isPersonal) ? <button className="tabBtn" onClick={(e) => {e.stopPropagation(); this.props.dispatch(setSharingProject(this.props.project)); this.props.dispatch(setEditingProject(null))}}><h3>Share</h3></button> : null}
                    </span>
                    <form onSubmit={this.handleSubmit}>
                        <h4>Project Name<span className="obligatory">*</span></h4>
                        <div className="inputContainer">
                            <input
                                type="text"
                                className={"input inputProjectName" + (canEdit ? "" : " disabled")}
                                name="projectName"
                                onChange={this.handleChange}
                                placeholder="Enter the project title here"
                                value={this.state.projectName}
                                autoComplete="off"
                                disabled={!canEdit}
                                required
                                /> 
                        </div> 

                        <h4>Description</h4>
                        <div className="inputContainer">
                            <textarea
                                className={"input inputProjectDescription" + (canEdit ? "" : " disabled")}
                                name="projectDescription"
                                onChange={this.handleChange}
                                placeholder="Enter the project description here"
                                value={this.state.projectDescription}
                                autoComplete="off"
                                disabled={!canEdit}
                                
                                />  
                        </div>
                        {canEdit  ? 
                            <div className="submitContainer">
                                <button className={this.state.projectName.length > 0 ? "stdBtn inputProjectSave":"stdBtn disabled inputProjectSave"} type="submit">{this.state.projectId ? "Save":"Create"}</button>
                            </div>      : null }     
                    </form>
                    
                </Scrollbar>
            
            </div>
        
        
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.projects.company,
    username: state.authParams.username,
    project: state.projects.editingProject,
    interface: state.globalInterface
  }
}

export default connect(mapStateToProps)(ProjectProperties)


