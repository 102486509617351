import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'


import InitialRouter from "../interfaceParts/InitialRouter"
import NavHeader from "../interfaceParts/NavHeader"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import Notification from "../interfaceParts/Notification"

import {
  checkLoggedIn,
  confirmMail,
} from '../redux/actionsAuth'

class PlatformsPage extends Component {
    componentWillMount() {
        this.props.dispatch(checkLoggedIn())
        if ((this.props.location.search.length > 60) && (!this.props.location.search.includes("utm_")))
            this.props.dispatch(confirmMail(this.props.location.search))
    }

    render() {
        
        if (this.props.shouldRedirectToLogin)
            return (<Redirect to='/login' />)

        if (!this.props.loggedIn)
            return (<Redirect to='/login' />)

        var error = null
        if (this.props.error && (this.props.error.length > 0))
            error = <div className="error">{this.props.error}</div>  
        
        var lib = null
        if (this.props.match.params && this.props.match.params.lib)
            lib = this.props.match.params.lib
        
        return (
            <div className="App">
                <NavHeader/>
                {error}
                <InitialRouter/>  
                <LoadingSpinner show={this.props.loading} />
                <Notification/>
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.authParams.error,
        loggedIn: state.authParams.loggedIn,
        loading: state.globalInterface.loading,
        shouldRedirectToLogin: state.authParams.shouldRedirectToLogin,
        username: state.authParams.username,
        subscribingPlatform: state.platforms.subscribingPlatform

    }
}


export default connect(mapStateToProps)(PlatformsPage)


