import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'

import Notification from "../interfaceParts/Notification"

import ProfileBrowser from "../interfaceParts/ProfileBrowser"
import NavHeaderHub from "../interfaceParts/NavHeaderHub"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import {
  assertLoggedIn
} from '../redux/actionsAuth'

class ProfilePage extends Component {
    componentWillMount() {
        this.props.dispatch(assertLoggedIn())
    }

    render() {
        if (this.props.shouldRedirectToLogin)
            return (<Redirect to='/login' />)

        return (
            <div className="App">
                <NavHeaderHub/>
                <ProfileBrowser mode={this.props.match.params.mode}/>  
                <LoadingSpinner show={this.props.loading} />
                <Notification />
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.authParams.error,
        loggedIn: state.authParams.loggedIn,
        loading: state.globalInterface.loading,
        shouldRedirectToLogin: state.authParams.shouldRedirectToLogin,
        username: state.authParams.username

    }
}


export default connect(mapStateToProps)(ProfilePage)


