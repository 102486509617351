import config from 'react-global-configuration'

import {
    setLoading,
    setNotification,
    getAccessToken,
    setHelper
} from './actions'

import {
    authRefresh,
} from './actionsAuth'

export const PROJECTS_LOADED = 'PROJECTS_LOADED'
export const COMPANY_LOADED = 'COMPANY_LOADED'
export const SET_SHARING_PROJECT = 'SET_SHARING_PROJECT'
export const SET_EDITING_PROJECT = 'SET_EDITING_PROJECT'
export const SHOW_ONBOARDING = 'SHOW_ONBOARDING'
export const SHOULD_SHOW_MUST_INCLUDE_POPUP = 'SHOULD_SHOW_MUST_INCLUDE_POPUP'

export function setShouldShowMustIncludePopup (val) {
    return {
        type: SHOULD_SHOW_MUST_INCLUDE_POPUP,
        val
    }
}

export function showOnboarding (visible) {
    return {
        type: SHOW_ONBOARDING,
        visible
    }
}

export function setSharingProject (sharingProject) {
    return {
        type: SET_SHARING_PROJECT,
        sharingProject
    }
}

export function setEditingProject (project) {
    return {
        type: SET_EDITING_PROJECT,
        project
    }
}

export function onLoadProjects (projects) {
    return {
        type: PROJECTS_LOADED,
        projects
    }
}


export function onLoadCompany (company) {
    return {
        type: COMPANY_LOADED,
        company
    }
}


export function treatGenericResponse (response, dispatch) {
    if (response.error && (response.error.length > 0))
        dispatch(setNotification ("Error occured.", response.error))
    if (response.projects)
        dispatch(onLoadProjects(response.projects))
    if (response.company)
        dispatch(onLoadCompany(response.company))
}



export function serverCommunication (endpoint, conf, expiredCallback, extraExecOnSuccess) {
    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(expiredCallback)
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (endpoint === 'api/modifyProject') {
                        dispatch(setEditingProject(null))
                        if ((conf.projectId === null) || (conf.projectId === undefined))
                            dispatch(setSharingProject(result.project))
                    }

                    if (endpoint === 'api/resetUserPassword') {
                        alert ("New password: " + result.pass)
                    }

                    if (extraExecOnSuccess)
                        dispatch(extraExecOnSuccess)
                    dispatch(setLoading(false))
                    
                },
                (error) => {
                    dispatch(setLoading(false))
                    
                }
            )
    }
}

export function createUser (username, mail) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "username": username, "mail": mail, 'endpoint': 'createUser' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/createUser", 
                        conf, 
                        authRefresh({name:'createUser', module:'actionsProjects', params:arguments}), 
                        getCompany())   
}

export function resetUserPassword (mail) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "mail": mail , 'endpoint': 'resetUserPassword'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/resetUserPassword", 
                        conf, 
                        authRefresh({name:'resetUserPassword', module:'actionsProjects', params:arguments}), 
                        null)   
}


export function setUser (upid, enabled, admin) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "upid": upid, "enabled": enabled, "admin":admin, 'endpoint': 'alterUser' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/alterUser", 
                        conf, 
                        authRefresh({name:'setUser', module:'actionsProjects', params:arguments}), 
                        getCompany())   
    
}

export function setProjectShare(pid, upid, isShared) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "upid": upid, "pid": pid, "isShared":isShared, 'endpoint': 'alterProjectShare' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/alterProjectShare", 
                        conf, 
                        authRefresh({name:'setProjectShare', module:'actionsProjects', params:arguments}), 
                        getProjects())   
    
}

export function acceptTerms () {
    const conf = {
      method: "post",
      body: JSON.stringify({'endpoint': 'acceptTerms'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return serverCommunication ("api/acceptTerms", 
                        conf, 
                        authRefresh({name:'acceptTerms', module:'actionsProjects', params:arguments}), 
                        getCompany())
}

export function getCompany () {
    const conf = {
      method: "post",
      body: JSON.stringify ({'endpoint': 'getCompany'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/getCompany", 
                        conf, 
                        authRefresh({name:'getCompany', module:'actionsProjects', params:arguments}), 
                        null)   
}

export function getProjects (withCompany=false) {
    const conf = {
      method: "post",
      body: JSON.stringify({'endpoint': 'getProjects'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/projects", 
                        conf, 
                        authRefresh({name:'getProjects', module:'actionsProjects', params:arguments}), 
                        withCompany ? getCompany() : null)   
}


export function modifyProject (projectName, projectDescription, projectId) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "projectName": projectName, "projectDescription":projectDescription, "projectId":projectId, 'endpoint': 'modifyProject' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/modifyProject", 
                        conf, 
                        authRefresh({name:'modifyProject', module:'actionsProjects', params:arguments}), 
                        getProjects())   


}


export function deleteProject (deletingProjectId) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "projectId": deletingProjectId, 'endpoint': 'deleteProject' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/deleteProject", 
                        conf, 
                        authRefresh({name:'deleteProject', module:'actionsProjects', params:arguments}), 
                        getProjects())   
}

export function alterArchiveProject (projectId, isArchived) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "projectId": projectId, "isArchived":isArchived, 'endpoint': 'alterArchiveProject' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication ("api/alterArchiveProject", 
                        conf, 
                        authRefresh({name:'alterArchiveProject', module:'actionsProjects', params:arguments}), 
                        getProjects())   

}

export function dismissHelper (helper) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "hid": helper.id, 'endpoint': 'dismissHelper' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return dispatch => {
        dispatch(setHelper(null))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'dismissHelper', module:'actionsProjects', params:arguments}))
                return res.json()})
            .then(
                (result) => {
                },
                (error) => {
                }
            )
    }
    
}