import React, { Component } from "react";
import { connect } from 'react-redux'
import { Link} from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Avatar from 'react-avatar';

import zxcvbn from 'zxcvbn'


import '../css/ProfileBrowser.scss';

import {
    assertLoggedIn,
    logout,
    redirectToLogin
  } from '../redux/actionsAuth'

  import {
    alterFields
  } from '../redux/actions'

  import {
    cancelSubscription,
    getProfile,
    alterProfile
  } from '../redux/actionsPlatforms'


class ProfileBrowser extends Component {

    state = {
        alteredFields: {},
        pScore: {score:0}
    };

    componentWillMount() {
        this.props.dispatch(assertLoggedIn())
        this.props.dispatch (getProfile())
    }

    handleFieldChange = e => {
        this.props.dispatch (alterFields ( {
            [e.target.name]: e.target.value
        }))

        if (e.target.name === 'npass')
            this.setState ({pScore: zxcvbn(e.target.value)})
    };
    
    render(show) {
        var that = this
        
        var menu = [["Edit profile", "edit"], ["Security", "security"], ["Orders", "orders"]].map (function (menuItem) {
            return <Link key={menuItem[1]} className={menuItem[1] === that.props.mode ? "active": ""} to={"/profile/" + menuItem[1]}>{menuItem[0]}</Link>
        })

        var contents = null
        
        if (this.props.mode === 'edit')
        {
            var items = [["First name", "name"], ["Last name", "surname"], ["VAT Country", "VAT"], ["VAT Number", "VATnumber"], ["Phone", "phone"], ["Company name", "companyName"], ["Billing name / address", "billingDetails"]].map (function(inp) {
                if (inp[1] === "billingDetails")
                    return <div className={"field " + inp[1]} key={inp[1]}>
                        <div className="control withFloatingLabel">
                            <textarea
                                className={"input " + inp[1]}
                                name={inp[1]}
                                onChange={that.handleFieldChange}
                                value={that.props.alteredFields[inp[1]] !== undefined ? that.props.alteredFields[inp[1]] : that.props.profile[inp[1]]}
                            />
                            <span className="floating-label">{inp[0]}</span>
                            
                        </div>
                    </div>
                return <div className={"field " + inp[1]}  key={inp[1]}>
                    <div className="control withFloatingLabel">
                        <input
                            className={"input " + inp[1]}
                            name={inp[1]}
                            onChange={that.handleFieldChange}
                            value={that.props.alteredFields[inp[1]] !== undefined ? that.props.alteredFields[inp[1]] : that.props.profile[inp[1]]}
                            maxLength={inp[1]==='VAT' ? 2:null}
                        />
                        <span className="floating-label">{inp[0]}</span>
                        
                    </div>
                </div>
            })

            contents = <div>
                {items}
                <Form.Check 
                                className="mt-3"
                                type="checkbox"
                                label="I accept to be contacted directly or via the newsletter (we promise to send you only a valuable information)"
                                name={'subscribedCB'}
                                checked={that.props.alteredFields["subscribed"] !== undefined ? that.props.alteredFields["subscribed"] : that.props.profile["subscribed"]}
                                onChange={(e) => {
                                    this.props.dispatch (alterFields ({
                                        subscribed: e.target.checked
                                    }));
                                }}
                            />
                {Object.keys (that.props.alteredFields).length > 0 ? <button className="ml-0 mt-5" onClick={(e)=>{that.props.dispatch(alterProfile(that.props.alteredFields))}}>Save Changes</button> : null}
            </div>
        }

        if (this.props.mode === 'security')
        {
            items = [["Old password", "opass"], ["New password", "npass"], ["Confirm new password", "cnpass"]].map (function(inp) {
                return <div className="field" key={inp[1]}>
                    <div className="control">
                        <input
                            type="password"
                            className="input"
                            name={inp[1]}
                            onChange={that.handleFieldChange}
                            value={that.props.alteredFields[inp[1]] !== undefined ? that.props.alteredFields[inp[1]] : ""}
                            placeholder={inp[0]}
                        />
                        {(inp[1] === 'npass') ?
                            <div className="pstrength">
                                <span className={"passwordStrength ps" + that.state.pScore.score}><span className="p1"><span/></span><span className="p2"><span/></span><span className="p3"><span/></span><span className="p4"><span/></span></span>
                                {that.state.pScore.feedback && that.state.pScore.feedback.warning ? <span className="passwordHint">{that.state.pScore.feedback.warning}</span> : null}
                            </div> :null }
                    </div>
                </div>
            })

            contents = <div>
                {items}
                {(that.props.alteredFields.npass && that.props.alteredFields.opass && (that.state.pScore.score > 2) && (that.props.alteredFields.opass.length > 5) && (that.props.alteredFields.cnpass) && (that.props.alteredFields.cnpass === that.props.alteredFields.npass)) ? <button className="ml-0 mt-5" onClick={(e)=>{that.props.dispatch(alterProfile(that.props.alteredFields, true))}}>Change password</button> : null}
            </div>
        }

        if (this.props.mode === 'orders')
        {
            if (!this.props.profile.licenses || (this.props.profile.licenses.length === 0))
                contents = <h4>You will see your subscriptions here</h4>
            else 
            {
                items = this.props.profile.licenses.map (function(license) {
                    return <Row className="license" key={"lic" + license.id}>
                        <Col md={2}>{license.platform.name}</Col>
                        <Col md={2}>{license.created}</Col>
                        <Col md={1}>{license.type}</Col>
                        <Col md={2}>{license.validUntil}</Col>
                        <Col md={1}>{license.accessOpen ? "Yes":"No"}</Col>
                        <Col md={4}>
                            {license.accessOpen ? <a href={license.platform.url} target="_blank" rel="noreferrer" className="button ml-0 lowPadding">Go to platform</a>
                                : null}
                            {(license.accessOpen && license.cancellable && (license.type !== 'trial')) ? <button className="transparentBtn lowPadding" onClick={(e)=>{that.props.dispatch(cancelSubscription(license))}}>Cancel subscription</button>
                            : null}
                        </Col>
                    </Row>
                })

                contents = <div>
                    <h3>Here is the history of your subscriptions</h3>
                    <div className="orders">
                        <Row className="header">
                            <Col md={2}>Platform</Col>
                            <Col md={2}>Acquired</Col>
                            <Col md={1}>Type</Col>
                            <Col md={2}>Valid until</Col>
                            <Col md={1}>Access open</Col>
                            <Col md={4}>
                            </Col>
                        </Row>
                        {items}
                    </div>
                </div>
            }
        }

        
        return (
        <div className="ProfileBrowser">
            <div className="fullSize" >
                <Scrollbar noScrollX={true}>
                    <Container fluid className="stdPadded subheader">
                        <Row>
                            <Col md={12}>
                                <span className="avatar">
                                    <Avatar name={this.props.profile.name + ' ' + this.props.profile.surname} size={70} round={true}/>
                                </span>
                                <span className="id">
                                    <h3>{this.props.profile.name} {this.props.profile.surname}</h3>
                                    <h4>{this.props.profile.login}</h4>
                                </span>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className="stdPadded">
                        <Row>
                            <Col md={2} className="menu">
                                {menu}
                                <button className="textLikeBtn ml-0" onClick={(e)=>{e.stopPropagation(); this.props.dispatch(logout()); this.props.dispatch(redirectToLogin()) }}>Sign out</button>
                            </Col>
                            <Col md={10}>
                                {contents}
                            </Col>
                        </Row>

                    </Container>
                            
                </Scrollbar>
            </div>
            

        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.globalInterface.loading,
        profile: state.authParams.profile,
        username: state.authParams.username,
        interface: state.globalInterface,
        loggedIn: state.authParams.loggedIn,
        alteredFields: state.globalInterface.alteredFields
        
    }
}

export default connect(mapStateToProps)(ProfileBrowser)


