import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import '../css/NavHeaderHub.scss';
import logo from '../img/monitor/logo.png'



import Monitor from "../interfaceParts/Monitor"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"


import {
  checkLoggedIn,
} from '../redux/actionsAuth'

class MonitorPage extends Component {
    componentWillMount() {
        this.props.dispatch(checkLoggedIn())
    }

    render() {    
        var error = null
        if (this.props.error && (this.props.error.length > 0))
            error = <div className="error">{this.props.error}</div>  
        
        return (
            <div className="Monitor">
                <Navbar className="navHub" bg="light" expand="lg">
                    <Container fluid className="" style={{paddingLeft:"15px"}}>
                        <Row>
                            <Col md={6}>
                                <Navbar.Brand href="/monitor"><img src={logo} className="logo" alt="logo" /></Navbar.Brand>
                                <a className="button" target="_blank" href="https://eisphoria.com/public-libraries">About EisphorIA</a>
                            </Col>
                            <Col md={6} className="align-right">
            
                                
                            </Col>
                            </Row>
                    </Container>    
                </Navbar>
                {error}
                <Monitor/>  
                <LoadingSpinner show={this.props.loading} />
                {this.props.loggedIn ? null : <div dangerouslySetInnerHTML={{__html: '<script type="text/javascript"> _linkedin_partner_id = "3970473"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); </script><script type="text/javascript"> (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk); </script> <noscript> <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3970473&fmt=gif" /> </noscript>'}}/> }
                {this.props.loggedIn ? null : <div dangerouslySetInnerHTML={{__html: '<script async src="https://www.googletagmanager.com/gtag/js?id=UA-160375095-1"></script><script>window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "UA-160375095-1");</script>'}}/> }

            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.authParams.error,
        loggedIn: state.authParams.loggedIn,
        loading: state.globalInterface.loading,
        username: state.authParams.username,
        
    }
}


export default connect(mapStateToProps)(MonitorPage)


