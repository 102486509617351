import Cookies from 'universal-cookie'


export const LOADING = 'LOADING'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SHOW_QS = 'SHOW_QS'
export const ALTER_FIELDS = 'ALTER_FIELDS'
export const SET_TUTORIAL_ELEMENTS = 'SET_TUTORIAL_ELEMENTS'
export const SET_URL = "SET_URL"
export const SHOW_TERMS = 'SHOW_TERMS'
export const SET_HELPER = 'SET_HELPER'
export const SHOW_MI_HELPER = 'SHOW_MI_HELPER'
export const SET_SHOWING_SOURCE = 'SET_SHOWING_SOURCE'
export const SET_SHOWING_LANGUAGE = 'SET_SHOWING_LANGUAGE'
export const SET_PLATFORM_DISABLED = 'SET_PLATFORM_DISABLED'
export const SET_QUERY_FORM_MODE = 'SET_QUERY_FORM_MODE'

export function setQueryFormMode (queryFormMode) {
    return {
        type: SET_QUERY_FORM_MODE,
        queryFormMode
    }
}


export function setPlatformDisabled (platformID, enabled) {
    return {
        type: SET_PLATFORM_DISABLED,
        platformID,
        enabled
    }
}


export function setShowingSource (src) {
    return {
        type: SET_SHOWING_SOURCE,
        src
    }
}

export function setShowingLanguage (lang) {
    return {
        type: SET_SHOWING_LANGUAGE,
        lang
    }
}


export function setMIHelper (visible) {
    return {
        type: SHOW_MI_HELPER,
        visible
    }
}


export function alterFields (changes) {
    return {
        type: ALTER_FIELDS,
        changes
    }
}

export function setURL (url) {
    return {
        type: SET_URL,
        url
    }
}

export function setTutorialElements (elements, update=true) {
    return {
        type: SET_TUTORIAL_ELEMENTS,
        elements,
        update
    }
}

export function onShowQS (visible) {
    return {
        type: SHOW_QS,
        visible
    }
}


export function onShowTerms (visible) {
    return {
        type: SHOW_TERMS,
        visible
    }
}



export function getAccessToken () {
    var auth = (new Cookies()).get("sysauth")
    if ((auth === undefined) || (auth === null))
        return undefined
    else return auth.access_token
}

export function setLoading (isLoading, loadingProgress) {
    return {
        type: LOADING,
        isLoading,
        loadingProgress
    }
}

export function setHelper (helper) {
    return {
        type: SET_HELPER,
        helper
    }
}


export function setNotification (header=null, content=null, ntype='bottom', nclass='error') {
    return {
        type: SET_NOTIFICATION,
        header,
        content,
        ntype,
        nclass
    }
}

