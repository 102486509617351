import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Dropdown} from "react-bootstrap";

import Scrollbar from 'react-scrollbars-custom';

//import {Line} from 'react-chartjs-2';


import DocViewer from "./DocViewer"
import SavedDocsBrowser from "./SavedDocsBrowser"
import ActivityBrowser from "./ActivityBrowser"
import WatchesBrowser from "./WatchesBrowser"
import '../css/BrowserApp.scss'

import StringUtils from "../utils/StringUtils";

import { ReactComponent as PrintIcon} from '../img/ic_print.svg'

import { ReactComponent as ListImg} from '../img/ic_listview.svg'
import { ReactComponent as TimelineImg} from '../img/ic_timeline.svg'

import { ReactComponent as ImgFlag} from "../img/ic_card-flag.svg";
import { ReactComponent as ImgHighlight} from "../img/ic_card-highlight.svg";
import { ReactComponent as ImgComment} from "../img/ic_card-comment.svg";


import { ReactComponent as SortImg} from '../img/ic_sort.svg'

import { ReactComponent as SavedListItem} from '../img/ic_card-saved.svg'
import { ReactComponent as ReadListItem} from '../img/ic_card-viewed.svg'

import { ReactComponent as GvImg} from '../img/ic_cb_empty.svg'
import { ReactComponent as GvImgActive} from '../img/ic_cb_active.svg'


import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import GHelper from './GHelper'
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'

import localConfig from '../config/config'
import globalConfig from '../configGlobal/config'


import {
  onResultsShouldRedraw,
  getProjectParams,
  setBrowsingDoc,
  onShowResultsMode,
  setGroupVersions,
  setGroupCase,
  postSearch,
  shouldPostSearch,
  setStartPosition
} from '../redux/actionsProject'

import {
    setShowingSource,
    setNotification
} from '../redux/actions'


class BrowserApp extends Component {
    constructorName = "BrowserApp"
    maxDocsUpperBound = 40

    inRangeDocScores = []

    state = {
        maxDocs : 40,
        hoveredID: -1,
        mouseX: 0,
        mouseY: 0,
        filtersVisible: false,
        sortByMenuShown: false,
        sortBy: 'Relevance',
        timelineXScale: 1.0,
        timelineXMargin: 0,
        timelineMouseDownPosition: null
    }

    constructor(props) {
        super(props)
        this.listRef = React.createRef()
    }

  
    formatDateMS (dateMS) {
        var date = new Date(dateMS)
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        if (monthIndex < 9)
            return day + '/0' + (monthIndex + 1) + '/' + year;
        else
            return day + '/' + (monthIndex + 1) + '/' + year;
        
        }

    dateToPosition (d, startDate, endDate, defValue=0) {
        return (endDate - startDate) > 0 ? (d - startDate) / (endDate - startDate) : defValue 
    }

	docScore (doc) {
        return doc.score
    }
    
	isDocInRange (doc, seenGrouppedRefs) {
		var res = true;
        
        if (this.props.query.groupVersions && seenGrouppedRefs.includes(doc.ref))
            res = false
        
        if (res)
            Array.prototype.push.apply(seenGrouppedRefs, doc.similarDocsRefs)
        
      	return res
    } 

    card(doc) {
      return <div>
                <div className="card-col-left">
                    {(doc.dateString) ? <p><b>Document date:<br/></b>{doc.dateString}</p> : null}
                    {(doc.language) ? <p><b>Language:<br/></b>{doc.language}</p> : null}
                </div>
                <div className="card-col-right">
                    {(doc.caption) ? <p><b>{doc.caption}</b></p> : null }
                    {(doc.summary) ? <p>{doc.summary}</p> : null }
                </div>
                {((doc.annotations) && (doc.annotations.length > 0)) ? 
                    <div className="card-bottom">
                        { doc.annotations.map(function (ann) 
                            { 
                                return  <div key={"ann" + ann.id} className={"doc-annotation"}>
                                            { ann.comments.map(function (com) 
                                                { 
                                                    return  <div key={"com" + com.id} className={"annotation-text-container color-" + ann.color}>
                                                                <b>{com.author} ({com.modified}):</b>{com.text}
                                                            </div> 
                                                }) 
                                            }
                                        </div> 
                            }) 
                        }
                    </div>: null
                }
            </div>
    }

    handleCursorMove = (e) => {
        if (this.state.timelineMouseDownPosition)
            this.setState ({timelineXMargin:this.state.timelineXMargin + (this.state.timelineMouseDownPosition - e.pageX) / this.state.timelineXScale, timelineMouseDownPosition: e.pageX})
        
        
    }

    componentDidMount() {
        if (!this.props.project) 
            this.props.dispatch (getProjectParams(this.props.projectId, false, false, true))

        document.addEventListener('mousemove', this.handleCursorMove)
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleCursorMove)
    }

    compilePlatformConfigs ()
    {
        var pConfigs = {}
        var qLanguage = this.props.query.treatmentLanguage ? this.props.query.treatmentLanguage : this.props.projects.company.defaultLanguage
    
        for (var pid of Object.keys(this.props.company.accessiblePlatforms))
        {
            if ((pid in this.props.query.unselectedPlatforms) && (this.props.query.unselectedPlatforms[pid]))
                continue
            if (qLanguage && this.props.company.langToPlatforms && this.props.company.langToPlatforms[qLanguage] && (!this.props.company.langToPlatforms[qLanguage].includes(this.props.company.accessiblePlatforms[pid].id)))
                continue

            if (pid in this.props.query.changedFilters)
                pConfigs[pid] = {"searchQuery": {"changedFilters":this.props.query.changedFilters[pid]}}
            else 
                pConfigs[pid] = {"searchQuery": {"changedFilters":{}}}
        }
        return pConfigs
    }

    componentDidUpdate(){   
        if (this.props.query.shouldPostSearch)
        {
            console.log (111, this.props.query)
            this.props.dispatch (shouldPostSearch(false))
            this.props.dispatch (postSearch (this.props.query, this.props.project ? this.props.project.id : this.props.projectId, this.props.startPosition, this.compilePlatformConfigs()))
        }
        if (this.props.shouldRedrawChart)
        {
            this.setState ({
                maxDocs : 40,
            })
            this.props.dispatch (onResultsShouldRedraw(false))
        }
    }

    render() {
        var that = this
        if ((! this.props.browsingResult) || (! this.props.browsingResult.docs) || (this.props.browsingResult.docs.length === 0))
        {
            var hint = <div className="hint"><div className='contents'><h4><span class="infoIcon">i</span>No results found</h4></div></div>
            //if (this.props.query.selectionMust.length > 0)
            //    hint = <div className="hint"><div className='contents'><h4><span class="infoIcon">i</span>No results found</h4><div>This is due to the very restrictive "must include" setting. <br/>There are no documents from this source containing the must include you have set.<br/>Remove it or cut into smaller parts or check other sources</div><button className="unfilled" onClick={(e)=>{that.props.dispatch (setNotification ("What is Must include?", "The \"Must include\" and \"Must exclude\" functions are what we call 'targeting parameters'.  Their main objective is to drastically reduce the number of documents in the result list. Indeed, when you only work with contextual search, technically, almost all documents can still be related to your search (of course with different relevance measures).  This could be time consuming to review.   To counter balance this effect, we have equipped EisphorIA with these targeting parameters allowing you to exclude documents from the results list.\n Therefore, when you use a must include, you are basically telling the algorithms that only documents contextually matching your search query and including this word (set as must include) should be included in the results. Must exclude has a similar concept.  You tell the algorithms that all documents containing the must exclude setting have to be excluded from the results list even if they are matching from a contextual point of view.", "popup", "info"))}}>What is "Must include"?</button>  </div></div>
        }
        //else if (this.props.query.selectionMust.length === 0)
        //    hint = <div className="hint"><div className='contents'><h4><span class="infoIcon">i</span>Too many results?</h4><div>Likely due to the fact you don't have any "must include" set up.  <br/>Try defining technical concepts, names, dates, references as "must includes". Only documents matching contextually your query and containing these words will be included in the results list.</div><button className="filled" onClick={(e)=>{document.querySelectorAll('.ReactTags__tagInputField')[0].focus();}}>Add must include </button> <button className="unfilled" onClick={(e)=>{that.props.dispatch (setNotification ("What is Must include?", "The \"Must include\" and \"Must exclude\" functions are what we call 'targeting parameters'.  Their main objective is to drastically reduce the number of documents in the result list. Indeed, when you only work with contextual search, technically, almost all documents can still be related to your search (of course with different relevance measures).  This could be time consuming to review.   To counter balance this effect, we have equipped EisphorIA with these targeting parameters allowing you to exclude documents from the results list.\n Therefore, when you use a must include, you are basically telling the algorithms that only documents contextually matching your search query and including this word (set as must include) should be included in the results. Must exclude has a similar concept.  You tell the algorithms that all documents containing the must exclude setting have to be excluded from the results list even if they are matching from a contextual point of view.", "popup", "info"))}}>What is "Must include"?</button>  </div></div>
   
        if (this.props.browsingResult && this.props.browsingResult.params)
        {
            var colorPalete = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'];
            var netDocs = [...this.props.browsingResult.docs]
            netDocs.sort((a,b) => (a.score > b.score) ? -1 : 1);
            
            var selectedDocs = 0
            this.inRangeDocScores = []
            var seenGrouppedRefs = []
            for (var i = 0; i < netDocs.length; i++)
            {
                if (that.isDocInRange(netDocs[i], seenGrouppedRefs))
                {
                    this.inRangeDocScores.push ([netDocs[i].score])
                    if (selectedDocs < parseInt(this.state.maxDocs))
                    {
                        netDocs[i].inSelection = selectedDocs
                        selectedDocs += 1
                    }
                    else
                        netDocs[i].inSelection = -1
                }
                else
                    netDocs[i].inSelection = -1
            }

            if (this.state.sortBy === 'Name')
                netDocs.sort((a,b) => (a.caption > b.caption) ? 1 : -1);
            else if (this.state.sortBy === 'Date descending')
                netDocs.sort((a,b) => (a.dateString > b.dateString) ? -1 : 1);
            else if (this.state.sortBy === 'Date ascending')
                netDocs.sort((a,b) => (a.dateString > b.dateString) ? 1 : -1);
            
            var selectedDocsRefs = []
            for (i = 0; i < netDocs.length; i++)
                if (netDocs[i].inSelection >= 0)
                    selectedDocsRefs.push ([netDocs[i].ref])

            if (this.props.interface.showResultsMode === 'list')
            {
                var docsCards = netDocs.map(function (d, indexCounter) { 
                    const cClasses = ['docCard'];
                    if (d.inSelection < 0)
                        cClasses.push ('hidden')
                    if (that.props.sessionSeenDocs.includes (d.ref))
                        cClasses.push ('sessionSeen')
                    if (that.props.browsingDocId === d.ref) { cClasses.push('opened'); }
                    else if (that.props.browsingDocId !== null) { cClasses.push('notopened'); }


                    var scoreIndicatorsNumber = 5
                    var scoreIndicators = []
                    for (var i = 0; i < scoreIndicatorsNumber; i += 1)
                        scoreIndicators.push (<span className={"scoreIndicator " + ((d.score * scoreIndicatorsNumber) > i ? "active":"")} key={"si"+d.id + 'p'+i}></span>  )

                    return (
                        <Col className={cClasses.join(' ')}  md={12} key={"listcard_" + indexCounter + '_' +d.id} id={"doc-" + d.id} onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language, selectedDocsRefs)); }}>
                            {that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <div className="docCardGroupMarker"></div> : null}
                            {that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <div className="docCardGroupMarker2"></div> : null}
                            <div >
                                <div className="contents">
                                    <Row>
                                        <Col md={12}>
                                            <div className="relevancy">{scoreIndicators}</div>
                                            <div className="flags">        
                                                {
                                                    (d.annotations && (d.annotations.length > 0)) ?
                                                        <span>
                                                            { d.annotations.map(function (ann) 
                                                                { 
                                                                    return <span key={'annitem' + ann.id} className={"annItem flag" + StringUtils.capitalize(ann.color)}><ImgFlag /> {ann.comments.length > 1 ? ("" + ann.comments.length):null} </span>
                                                                }) 
                                                            }
                                                        </span>
                                                        
                                                        :
                                                        null
                                                }
                                                { d.commentsNum > 0 ? <span className="annItem flagNeutral"><ImgComment />{(d.commentsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                                { d.highlightsNum > 0 ? <span className="annItem flagNeutral"><ImgHighlight />{(d.highlightsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                                
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <h4 className="projectTitle">{ (d.read && localConfig.VIEWED_ENABLED) ? <ReadListItem className="listItemReadIcon"/> : null} {(that.props.savedDocs && (that.props.savedDocs.map(function (d) { return d.id }).includes(d.id)) ? <SavedListItem className="listItemSavedIcon"/> : null)} {d.caption}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fields">
                                            <Col md={12}>
                                                <span className="value">{d.source.name}</span>
                                                <span className="value">{d.dateString}</span>
                                                <span className="value">{d.language}</span>
                                            </Col>
                                    </Row>
                                    {(d.summary) && (d.summary.length > 3) ? <p className="description">{d.summary}</p> : null}
                                </div>
                            </div>
                        </Col>);
                })
            }
            else if (this.props.interface.showResultsMode === 'timeline')
            {
                var allCDates = [].concat.apply([], netDocs.map(function (d, index) { return d.inSelection < 0 ? [] : d.dates }));
                allCDates.sort()
                
                var startCDate = new Date(allCDates.length > 0 ? allCDates[0] : '2020-01-01').getTime(),
                    endCDate = new Date(allCDates.length > 0 ? allCDates[allCDates.length-1] : '2020-01-01').getTime();
                
                var cTimeFrame = endCDate - startCDate
                if (cTimeFrame < 1)
                    cTimeFrame = 1
                

                var tt = <div className="cardtooltip empty"></div>
                var currentIndex = 0
                var timelineNodes = netDocs.map(function (d, index) { 
                    var cscore = that.docScore (d)
                    var dx = cscore * 15 + 5
                    var tAnchor = "start"
                    var opacity = "1"
                    var display = 'block'
                    currentIndex += 1
                    if (d.inSelection < 0)
                    {
                        opacity = "0"
                        display = "none"
                        currentIndex -= 1
                    }
                    else if ((that.props.sessionSeenDocs.includes (d.ref)) && ((d.ref !== that.props.browsingDocId)))
                        opacity = "0.5"
                    	    
                    
                    if (d.id === that.state.hoveredID)
                        tt = <div className="cardtooltip" style={{left: that.state.mouseX > 500 ? that.state.mouseX - 440 : that.state.mouseX + 30, top: that.state.mouseY - 80}}>{ that.card (d)}</div>
                    
                    var strokeWidth = (d.ref === that.props.browsingDocId) ? "1px" : "0px"
                    
                    var shiftedDates = d.dates.length > 0 ? d.dates.map (function (cdate) { return [10 + that.state.timelineXScale * (300 * (new Date(cdate).getTime() - startCDate) / cTimeFrame  - that.state.timelineXMargin), cdate]}) : [[0,""]]
                    var thumbs = shiftedDates.map (function (cd, cdindex) { return <g key={d.id + '_thumb_' + cdindex} className="thumb">
                                                                                        {((cdindex === 0) || (cdindex === shiftedDates.length-1)) && that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <circle cx={cd[0] + 'px'} r={cscore * 8 + 4} style={{"fill": "none", "strokeWidth":"1","stroke":"#777777"}}></circle> : null}
                                                                                        {((cdindex === 0) || (cdindex === shiftedDates.length-1)) && that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <circle cx={cd[0] + 'px'} r={cscore * 8 + 2} style={{"fill": "none", "strokeWidth":"1","stroke":"#777777"}}></circle> : null}
                            
                                                                                        <circle cx={cd[0] + 'px'} r={((cdindex === 0) || (cdindex === shiftedDates.length-1)) ? cscore * 8 : 3} style={{"fill": colorPalete[d.partition % colorPalete.length], "strokeWidth":strokeWidth}}></circle>
                                                                                        <g><text x={cd[0] + 'px'} dy="-10px" textAnchor="middle" filter="url(#solid)">{cd[1]}</text><text x={cd[0] + 'px'} dy="-10px" textAnchor="middle" style={{fill:"#2c2c2c"}}>{cd[1]}</text></g>
                                                                                    </g>})
                    if (d.read && localConfig.VIEWED_ENABLED)
                        dx += 23

                    return (
                        <g key={d.id} style={{"opacity": opacity, "display":display}} className="node node--internal" transform={"translate(" + 0 + "," + (10 + currentIndex * (800 / Math.max (that.state.maxDocs, 1))) +  ")"}  onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language, selectedDocsRefs)); if (that.constructorName in that.props.interface.tutorialActiveElements) {that.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[that.constructorName][4].nextStepRule))}}} >
                            <line x1={shiftedDates[0][0]} x2={shiftedDates[shiftedDates.length - 1][0]} style={{"stroke": colorPalete[d.partition % colorPalete.length], "strokeWidth":1}}></line>
                            {thumbs}
                            {that.props.savedDocs && (that.props.savedDocs.map(function (d) { return d.id }).includes(d.id)) ? 
                                <g transform={"translate (" + (shiftedDates[shiftedDates.length - 1][0] - cscore * 8 * 5 / 8) + " -" + cscore * 8 * 3 / 4 * 5 / 7 + ") scale(" + cscore * 1.5 + ", " + cscore * 1.5 + ")" } id="Page-1-(UPD)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                    <polyline id="Path-Copy-3" stroke="#FFFFFF" strokeWidth="1.5" points="5.60742612 1.04666667 2.59825945 4.05583333 1 2.12024804"></polyline>
                                </g> : null }
                            
                                <g className="titleContainer">
                                    { (d.read && localConfig.VIEWED_ENABLED) ? <g transform={"translate(" + (dx - 23 + shiftedDates[shiftedDates.length - 1][0]) + ", -5)"} className="readIcon" fill="none" fillRule="evenodd">
                                        <g id="Artboard" transform="translate(-116.000000, -96.000000)">
                                            <g id="Group-11" transform="translate(109.000000, 90.000000)">
                                                <g id="eye-active" transform="translate(7.000000, 6.000000)">
                                                    <path d="M8,10 C10.9455187,10 13.6121853,8.33333333 16,5 C13.6121853,1.66666667 10.9455187,0 8,0 C5.05448133,0 2.38781467,1.66666667 0,5 C2.38781467,8.33333333 5.05448133,10 8,10 Z" id="Oval-Copy-6" fill="#FFFFFF"></path>
                                                    <path d="M8,2 C9.65685425,2 11,3.34314575 11,5 C11,6.65685425 9.65685425,8 8,8 C6.34314575,8 5,6.65685425 5,5 C5,4.91209506 5.00378078,4.82507316 5.01118859,4.73908806 C5.30333486,4.90519956 5.64059652,5 6,5 C7.1045695,5 8,4.1045695 8,3 C8,2.64059652 7.90519956,2.30333486 7.73924025,2.0118566 L7.82372721,2.00509269 L7.82372721,2.00509269 Z" id="Combined-Shape-Copy-3"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g> : null}
                                    {
                                        (d.annotations && (d.annotations.length > 0)) ?
                                            <g className="flags">
                                                { d.annotations.map(function (ann) 
                                                    { 
                                                        dx += 12
                                                        return <g key={'ann' + ann.id} className={"flag flag-" + ann.color} transform={"translate(" + (dx - 15 + shiftedDates[shiftedDates.length - 1][0]) + ", -5)"} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="Artboard" transform="translate(-416.000000, -96.000000)">
                                                                        <g id="Group-3" transform="translate(406.000000, 90.000000)">
                                                                            <path d="M9.96483178,8 L19,8 C19.5522847,8 20,8.44771525 20,9 L20,15 C20,15.5522847 19.5522847,16 19,16 L9.96483178,16 C9.41254703,16 8.96483178,15.5522847 8.96483178,15 C8.96483178,14.7885081 9.03188457,14.5824616 9.15635284,14.4114749 L10.9117647,12 L10.9117647,12 L9.15635284,9.58852511 C8.83131939,9.14201452 8.92979608,8.5165545 9.37630667,8.19152106 C9.54729338,8.06705279 9.75333992,8 9.96483178,8 Z" transform="translate(14.000000, 12.000000) rotate(-90.000000) translate(-14.000000, -12.000000) "></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                    }) 
                                                }
                                            </g>
                                            : null
                                    } 
                                    <text dy="0.4em" x={shiftedDates[shiftedDates.length - 1][0] + 'px'} dx={dx + 'px'} style={{"textAnchor": tAnchor, "fontSize": "12px", "fontWeight": (d.ref === that.props.browsingDocId) ? 700:400}} onMouseEnter={(e) => {that.setState ({ hoveredID :d.id, mouseX: e.pageX, mouseY: e.pageY})}} onMouseOut={() => {that.setState ({ hoveredID :-1})}}  >{d.caption.length > 50 ? d.caption.slice (0, 50-3) + '...' : d.caption}</text>
                                </g> 
                            
                        </g>
                    );
                })

                var ticksNum = 6 * that.state.timelineXScale
                
                var ticks = [...Array(ticksNum).keys()].map (function (tick) {
                    var xPos = Math.floor (10 + that.state.timelineXScale * (300.0 * tick / (ticksNum - 1)) - that.state.timelineXMargin )
                    return <g className="tick" key={"tick_" + tick}><line x1={xPos} x2={xPos} y1={0} y2={830} ></line><text x={xPos} y={840} textAnchor="middle">{ that.formatDateMS((startCDate + (endCDate - startCDate) * tick / (ticksNum - 1))) }</text> </g>
                })
                
                var selStart = 10 + that.state.timelineXScale * (300*((this.props.query.minCDate) ? this.dateToPosition(this.props.query.minCDate, startCDate, endCDate) : 0) - that.state.timelineXMargin )
                var selEnd = 10 + that.state.timelineXScale * (300*((this.props.query.maxCDate) ? this.dateToPosition(this.props.query.maxCDate, startCDate, endCDate) : 1) - that.state.timelineXMargin )


                var timelineVisual = 
                    <div className="timelineVisual">
                        <div className="scaleControlButtons">
                            <button className={"scaleControlButton minusButton" + (that.state.timelineXScale <= 1 ? " disabled":"")} onClick={(e)=>{e.stopPropagation(); if (that.state.timelineXScale > 1) that.setState({timelineXScale:that.state.timelineXScale / 2})}}>-</button>
                            <button className="scaleControlButton plusButton" onClick={(e)=>{e.stopPropagation(); that.setState({timelineXScale:that.state.timelineXScale * 2})}}>+</button>
                        </div>
                        <svg className="timeline"  viewBox="-20 0 500 850" preserveAspectRatio="xMinYMin meet" onMouseDown={(e)=>{that.setState({timelineMouseDownPosition:e.pageX})}}>
                            <defs>
                                <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
                                    <feFlood floodColor="#ffffff"/>
                                    <feComposite in="SourceGraphic" operator="xor" />
                                </filter>
                            </defs>
                            <g>
                                <g >
                                    <rect x={selStart} width={selEnd-selStart} y={0} height={830} className="selectedRangeRectangle"></rect>
                                    {ticks}
                                    <g className="nodes">{timelineNodes}</g>
                                    
                                </g>
                            </g>
                        </svg>
                    </div>
            }

            
            
            
        }
        else
            hint = <div className="hint"><div className='contents'><h4>Please enter a search query to find relevant documents here</h4></div></div>
        
        var subHeader = <div className="subheader">
                            <Container fluid>
                                <Row>
                                    <Col md={12}>
                                        <span className="float-right">
                                        {this.props.company.userProfile && this.props.company.userProfile.genericParams && this.props.company.userProfile.genericParams.showMainDocument ? <button className="gvButton" title={that.props.query.groupCase ? "Show relevant document":"Show main case document"} onClick={(e) => {that.props.dispatch(setGroupCase(!that.props.query.groupCase)); }}>
                                                            Show principal
                                                            <span className={'gvIndicator' + (that.props.query.groupCase ? " active":"")}><span></span></span>
                                            </button>:null}
                                            <button className="gvButton" title={that.props.query.groupVersions ? "Ungroup document versions":"Group document versions"} onClick={(e) => {that.props.dispatch(setGroupVersions(!that.props.query.groupVersions)); }}>
                                                            Group similar
                                                            <span className={'gvIndicator' + (that.props.query.groupVersions ? " active":"")}><span></span></span>
                                            </button>
                                            <button className="printTimelineButton" onClick={(e)=>{
                                                var pri = document.getElementById("contentstoprint");
                                                var queryHeader = '<div class="query"><h4>Query:</h4>' + this.props.query.query + '</div>'
                                                for (var el of this.props.query.selectionMust)
                                                    queryHeader = queryHeader + "<div><h5>Must include:</h5>" + el + "</div>"
                                                for (var el of this.props.query.selectionExclude)
                                                    queryHeader = queryHeader + "<div><h5>Must exclude:</h5>" + el + "</div>"
                                                if (this.props.query.minDate)
                                                    queryHeader = queryHeader + "<div><h5>Min date:</h5>" + this.timestampToCleanDate (this.props.query.minDate) + "</div>"
                                                if (this.props.query.maxDate)
                                                    queryHeader = queryHeader + "<div><h5>Max date:</h5>" + this.timestampToCleanDate (this.props.query.maxDate) + "</div>"
                                                if (this.props.query.minCDate)
                                                    queryHeader = queryHeader + "<div><h5>Min extracted date:</h5>" + this.timestampToCleanDate (this.props.query.minCDate) + "</div>"
                                                if (this.props.query.maxCDate)
                                                    queryHeader = queryHeader + "<div><h5>Max extracted date:</h5>" + this.timestampToCleanDate (this.props.query.maxCDate) + "</div>"

                                                pri.innerHTML = queryHeader + this.listRef.current.contentElement.innerHTML;
                                                //pri.innerHTML = document.getElementsByClassName("timelineCardsContainer")[0].innerHTML
                                                window.print()
                                                
                                            }}><PrintIcon/> Print list</button>
                                            <Dropdown drop={"down"} className="filterDropdown">
                                                <Dropdown.Toggle variant="light" className="sortByBtn">
                                                    <span className="weightRegular"><SortImg/></span> {this.state.sortBy}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu alignRight="true" flip={false} className="gvOptionsMenu optionsMenu withShadow">
                                                    {['Relevance', 'Name', 'Date descending', 'Date ascending'].map(function (d) { return <button key={'sortBy_' + d} className={that.state.sortBy === d ? 'active' : ''} onClick={(e) => { that.setState ({sortBy:d, sortByMenuShown:false, shownOptionsPanelID:null})}}>{d}</button> }) }
                                                    
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                        </span>
                                        <span>
                                            {this.props.projects.showingLanguage && this.props.projects.docs && (this.props.projects.showingLanguage in this.props.projects.docs) && this.props.company.langToPlatforms[this.props.projects.showingLanguage] && Object.keys (this.props.projects.docs[this.props.projects.showingLanguage]).length > 2 ?
                                                <Dropdown drop={"down"} className="filterDropdown sourceDropdown">
                                                    <Dropdown.Toggle variant="light" className="">
                                                        <span className="weightRegular">Source: </span> {this.props.projects.showingSource === 'Merged' ? 'Combined':this.props.company.accessiblePlatforms[this.props.projects.showingSource].name}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu flip={false} className="gvOptionsMenu optionsMenu withShadow">
                                                        {this.props.company.langToPlatforms[this.props.projects.showingLanguage].map(function (pid) { if (!that.props.projects.docs[that.props.projects.showingLanguage] || !(pid in that.props.projects.docs[that.props.projects.showingLanguage])) return null; return <button key={'showSource_' + pid} className={String(that.props.projects.showingSource) === String(pid) ? 'active' : ''} onClick={(e) => { that.props.dispatch (setShowingSource(pid))}}>{that.props.company.accessiblePlatforms[pid].name}</button> }) }
                                                        <button key={'showSource_Merged'} className={that.props.projects.showingSource === 'Merged' ? 'active' : ''} onClick={(e) => { that.props.dispatch (setShowingSource('Merged'))}}>Combined <span className="orange">Beta</span></button>
                                                        
                                                    </Dropdown.Menu>
                                                </Dropdown> : null}
                                        </span>
                                        
                                    </Col>
                                    
                                </Row>
                            </Container>
                        </div>

        var resultsView = (this.props.browsingResult && this.props.browsingResult.params) ? <div className="resultsBrowser">
                {subHeader}
                        
                {this.props.interface.showResultsMode === 'list' ? 
                        <div className="cardsContainer">
                            <Scrollbar ref={this.listRef} noScrollX={true}>
                                <Container fluid>
                                    {hint}
                
                                    {docsCards.length > 0 ?
                                        <Row className="listNavTop">
                                            <span className="position">Browsing documents {this.props.startPosition + 1} - {this.props.browsingResult.docs.length + this.props.startPosition} among {this.props.browsingResult.params.ffNum} according to your query and following filters.</span>
                                            <div className="alignCenter">
                                                {(this.props.startPosition > 40) ? <button className="toFirstPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(0)); this.props.dispatch(shouldPostSearch(true))}}>First page</button>:null}
                                                {(this.props.startPosition > 0) ? <button className="toPreviousPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(this.props.startPosition - 40)); this.props.dispatch(shouldPostSearch(true))}}>Previous page</button>:null}
                                            </div>
                                            
                                        </Row> : null}

                                    <Row className="docCardsInternalContainer">
                                        {docsCards}
                                    </Row>

                                    {(this.props.startPosition + 40 < this.props.browsingResult.params.ffNum) ?
                                        <Row className="listNavBottom">
                                            <button className="toNextPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(this.props.startPosition + 40)); this.props.dispatch(shouldPostSearch(true)); this.listRef.current.scrollTo(0,0)}}>To the next page with less relevant results</button>
                                            
                                        </Row> : null}
                                </Container>
                            </Scrollbar>
                        
                        </div>
                    : 
                    <div className="timelineContainer">
                            {timelineVisual}
                            {tt}
                    </div>  
                }
                
            </div>:null


        return (
        <div className="BrowserApp">
                { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                    <Joyride
                        tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                        styles={{options: globalConfig.JOYRIDE.options}}
                        disableCloseOnEsc={true}
                        disableOverlayClose={true}
                        disableOverlay={true}
                        disableScrolling={true}
                        showSkipButton={true}
                        hideBackButton={true}
                        run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                        locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                        continuous={false}
                        stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                        callback = { data => {
                                const { action, type } = data;
                                if (action === ACTIONS.SKIP)
                                    this.props.dispatch (setTutorialElements(null))
                                if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                {
                                    //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                    //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                    this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                }
                            
                                
                                
                            }
                        }
                    />
                    :null
                }
                
                
                { this.props.interface.activityVisible ? 
                    <ActivityBrowser projectId={this.props.project ? this.props.project.id : this.props.projectId} /> :
                    (
                        this.props.interface.watchesVisible ? <WatchesBrowser /> : 
                        <div className="Browser">
                            { this.props.interface.savedDocsVisible ? <SavedDocsBrowser projectId={this.props.project ? this.props.project.id : this.props.projectId} /> :
                            resultsView}
                            <DocViewer projectId={this.props.project ? this.props.project.id : this.props.projectId} topButtons={{close:true, save:true, print:true, searchWith:false, previous:false, next:false}} />
                        </div>)
                }
                {(this.props.browsingResult && this.props.browsingResult.params) || this.props.interface.savedDocsVisible ? <GHelper hParams={this.props.interface.savedDocsVisible ? {'SavedDocsBrowser':0}:{'BrowserApp':0}}/> : null}
                
        </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    interface: state.globalInterface,

    shouldRedrawChart: state.globalInterface.resultsShouldRedraw,
    showSavedDocs: state.globalInterface.showSavedDocs,
    showQS: state.globalInterface.showQS,

    browsingResult: ((state.projects.showingLanguage && (state.projects.showingLanguage in state.projects.docs) && state.projects.showingSource && (state.projects.showingSource in state.projects.docs[state.projects.showingLanguage])) ? state.projects.docs[state.projects.showingLanguage][state.projects.showingSource]:null),

    savedDocs: state.projects.savedDocs,

    browsingDocId: state.projects.browsingDocId,
    loadedDoc: state.projects.loadedDoc,
    username: state.authParams.username,
    query: state.query,
    sessionSeenDocs: state.projects.sessionSeenDocs,
    project: state.projects.currentProject,
    startPosition: state.projects.startPosition,
    company: state.projects.company,
    projects: state.projects,
    company: state.projects.company
  }
}

export default connect(mapStateToProps)(BrowserApp)



