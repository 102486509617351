import React from 'react';

import Loader from 'react-loader-spinner'
import '../css/LoadingSpinner.scss';

const LoadingSpinner = ({show, progress}) => {
  const componentClasses = ['loadingSpinnerContainer'];
  if (show) { componentClasses.push('show'); }
  
  return (
    <div className={componentClasses.join(' ')}>
        {progress ? <div className="progress">
                        <div className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        > {progress}% </div>
                    </div> : null}
        <Loader type="MutatingDots" color="#26458a" secondaryColor="#26458a"/>
    </div>
  );
};


export default LoadingSpinner;
