import 'core-js';
import 'react-app-polyfill/ie9';


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './redux/reducers'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker';

import LoginPage from './pages/LoginPage'
import InitialPage from './pages/InitialPage'
import ProfilePage from './pages/ProfilePage'
import ResetPassPage from './pages/ResetPassPage'
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import DocPage from './pages/DocPage';
import MonitorPage from './pages/MonitorPage';
import v2ProjectPage from './pages/v2_ProjectPage';
import v2ProjectsPage from './pages/v2_ProjectsPage';

import config from 'react-global-configuration'

import localConfig from './config/config'

config.set({ globalUrl: localConfig.API_URL, 'localConfig':localConfig });

const loggerMiddleware = createLogger()
function configureStore(preloadedState) {
  if (localConfig.DEBUG)
    return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunkMiddleware, loggerMiddleware)
    )
  else
    return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunkMiddleware)
    )
}



const store = configureStore ()

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/" component={InitialPage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/profile/:mode" component={ProfilePage} />
                <Route exact path="/reset/" component={ResetPassPage} />
                <Route exact path="/projects" component={ProjectsPage} />
                <Route exact path="/project/:pid/:mode/:docRef?" component={ProjectPage} />
                <Route exact path="/monitor/" component={MonitorPage} />
                <Route path="/project/:pid?" component={ProjectPage} />
                <Route path="/doc/:docRef?" component={DocPage} />

                <Route exact path="/v2/projects" component={v2ProjectsPage} />
                <Route exact path="/v2/project/:pid/:mode/:docRef?" component={v2ProjectPage} />
                <Route path="/v2/project/:pid?" component={v2ProjectPage} />
                
                
                
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
