import config from 'react-global-configuration'

import {
    getAccessToken,
    setLoading,
    setHelper,
} from './actions'

import {
    authRefresh,
} from './actionsAuth'

import {
    getCompany,
} from './actionsProjects'

export const SET_QUERY = 'SET_QUERY'
export const SET_QUERY_LANGUAGE = 'SET_QUERY_LANGUAGE'
export const ON_SEARCH_RESULTS_LOADED = 'ON_SEARCH_RESULTS_LOADED'
export const ON_QSSEARCH_RESULTS_LOADED = 'ON_QSSEARCH_RESULTS_LOADED'
export const ON_QSSEARCH_QUERY_CHANGED = 'ON_QSSEARCH_QUERY_CHANGED'
export const ON_PROJECT_PARAMS_LOADED = 'ON_PROJECT_PARAMS_LOADED'
export const ON_ONLY_PROJECT_LOADED = 'ON_ONLY_PROJECT_LOADED'
export const RESULTS_SHOULD_REDRAW = 'RESULTS_SHOULD_REDRAW'
export const SHOW_ACTIVITY = 'SHOW_ACTIVITY'
export const SHOW_QUERY_FORM = 'SHOW_QUERY_FORM'
export const SHOW_SAVED_DOCS = 'SHOW_SAVED_DOCS'
export const SHOW_WATCHES = 'SHOW_WATCHES'
export const SHOW_RESULTS_MODE = 'SHOW_RESULTS_MODE'
export const SET_BROWSING_DOC_ID = 'SET_BROWSING_DOC_ID'
export const DOC_LOADING = 'DOC_LOADING'
export const SET_LOADED_DOC = 'SET_LOADED_DOC'
export const ADD_SELECTION = 'ADD_SELECTION'
export const REMOVE_SELECTION = 'REMOVE_SELECTION'
export const CHANGE_QUERY = 'CHANGE_QUERY'
export const SET_TRANSLATION_STATE = 'SET_TRANSLATION_STATE'
export const SET_CHANGED_FILTERS = 'SET_CHANGED_FILTERS'
export const SET_DATES_FILTER = 'SET_DATES_FILTER'
export const SET_CDATES_FILTER = 'SET_CDATES_FILTER'
export const SET_GROUP_VERSIONS = 'SET_GROUP_VERSIONS'
export const SET_GROUP_CASE = 'SET_GROUP_CASE'
export const SET_MODIFIED_HIGHLIGHT_ID = 'SET_MODIFIED_HIGHLIGHT_ID'
export const SET_MODIFIED_COMMENT_ID = 'SET_MODIFIED_COMMENT_ID'
export const SHOULD_POST_SEARCH = 'SHOULD_POST_SEARCH'
export const ALTER_SAVED_DOC_ORDER_LOCALLY = 'ALTER_SAVED_DOC_ORDER_LOCALLY'
export const SET_START_POSITION = "SET_START_POSITION"
export const SET_CURRENT_DOC_READ = "SET_CURRENT_DOC_READ"
export const ALTER_CURRENT_PROJECT_EDITOR = "ALTER_CURRENT_PROJECT_EDITOR"
export const CLOSE_DOCUMENT = "CLOSE_DOCUMENT"
export const SET_USE_BY_DEFAULT = "SET_USE_BY_DEFAULT"

export function closeDocument () {
    return {
        type: CLOSE_DOCUMENT
    }
}


export function alterCurrentProjectEditor (upid, login, isEditor) {
    return {
        type: ALTER_CURRENT_PROJECT_EDITOR,
        upid,
        login,
        isEditor
    }
}

export function setUseByDefault (value) {
    return {
        type: SET_USE_BY_DEFAULT,
        value
    }
}

export function setStartPosition (startPosition) {
    return {
        type: SET_START_POSITION,
        startPosition
    }
}

export function shouldPostSearch (should) {
    return {
        type: SHOULD_POST_SEARCH,
        should
    }
}

export function setQuery (query) {
    return {
        type: SET_QUERY,
        query
    }
}


export function setQueryLanguage (queryLanguage) {
    return {
        type: SET_QUERY_LANGUAGE,
        queryLanguage
    }
}


export function changeQuery (query) {
    return {
        type: CHANGE_QUERY,
        query
    }
}

export function setChangedFilters (changedFilters, shouldPostSearch) {
    return {
        type: SET_CHANGED_FILTERS,
        changedFilters,
        shouldPostSearch
    }
}

export function setDatesFilter (datesParams, shouldPostSearch) {
    return {
        type: SET_DATES_FILTER,
        datesParams,
        shouldPostSearch
    }
}

export function setCDatesFilter (datesParams, shouldPostSearch) {
    return {
        type: SET_CDATES_FILTER,
        datesParams,
        shouldPostSearch
    }
}

export function setGroupVersions (groupVersions) {
    return {
        type: SET_GROUP_VERSIONS,
        groupVersions
    }
}

export function setGroupCase (groupCase) {
    return {
        type: SET_GROUP_CASE,
        groupCase
    }
}

export function setTranslationState (value) {
    return {
        type: SET_TRANSLATION_STATE,
        value
    }
}

export function addSelection (selectionType, value, position) {
    return {
        type: ADD_SELECTION,
        selectionType,
        value,
        position
    }
}

export function removeSelection (selectionType, index) {
    return {
        type: REMOVE_SELECTION,
        selectionType,
        index
    }
}


export function setBrowsingDoc (docId, lang, allRefs=[]) {
    return {
        type: SET_BROWSING_DOC_ID,
        docId,
        lang,
        allRefs
    }
}

export function onSearchResultsLoaded (result) {
    return {
        type: ON_SEARCH_RESULTS_LOADED,
        data: result
    }
}

export function onQSSearchResultsLoaded (result) {
    return {
        type: ON_QSSEARCH_RESULTS_LOADED,
        data: result
    }
}

export function onQSSearchQueryChanged (query) {
    return {
        type: ON_QSSEARCH_QUERY_CHANGED,
        query
    }
}

export function onProjectParamsLoaded (result) {
    return {
        type: ON_PROJECT_PARAMS_LOADED,
        result
    }
}


export function onResultsShouldRedraw (shouldRedraw) {
    return {
        type: RESULTS_SHOULD_REDRAW,
        shouldRedraw
    }
}

export function onShowQueryForm (visible) {
    return {
        type: SHOW_QUERY_FORM,
        visible
    }
}


export function onShowSavedDocs (visible) {
    return {
        type: SHOW_SAVED_DOCS,
        visible
    }
}

export function onShowWatches (visible) {
    return {
        type: SHOW_WATCHES,
        visible
    }
}

export function onShowResultsMode (mode) {
    return {
        type: SHOW_RESULTS_MODE,
        mode
    }
}

export function onShowActivity (visible, tab='All') {
    return {
        type: SHOW_ACTIVITY,
        visible,
        tab
    }
}

export function setDocLoading (isLoading) {
    return {
        type: DOC_LOADING,
        isLoading
    }
}

export function setLoadedDoc (doc) {
    return {
        type: SET_LOADED_DOC,
        doc
    }
}

export function setModifiedHighlightId (modifiedHighlightId) {
    return {
        type: SET_MODIFIED_HIGHLIGHT_ID,
        modifiedHighlightId
    }
}

export function setModifiedCommentId (modifiedCommentId) {
    return {
        type: SET_MODIFIED_COMMENT_ID,
        modifiedCommentId
    }
}

export function setCurrentDocRead (read) {
    return {
        type: SET_CURRENT_DOC_READ,
        read
    }
}


export function alterSavedDocOrderLocally (savedDocIndex, newOrder) {
    return {
        type: ALTER_SAVED_DOC_ORDER_LOCALLY,
        savedDocIndex,
        newOrder
    }
}

export function treatGenericResponse (response, dispatch) {
    if (response.project)
    {
        if (response.project.lastQuery && (response.project.lastQuery !== undefined))
            dispatch(setQuery(response.project.lastQuery))
        dispatch(onProjectParamsLoaded(response))
        //dispatch(setURL('/project/' + response.project.id))
    }            
    if (response.doc)
        dispatch(setLoadedDoc(response.doc)) 
    if (response.helper) 
        dispatch(setHelper(response.helper))
}

export function serverCommunication (conf, expiredCallback, loadingDocOnly, extraExecOnSuccess) {
    return dispatch => {
        if (loadingDocOnly)
            dispatch(setDocLoading(true))
        else
            dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(expiredCallback)
                return res.json()})
            .then(
                (result) => {
                    if (extraExecOnSuccess)
                        dispatch(extraExecOnSuccess)
                    treatGenericResponse(result, dispatch)
                    if (loadingDocOnly)
                    {
                        if (!result.doc)
                            dispatch(setBrowsingDoc (null, null))
                        dispatch(setDocLoading(false))
                    }
                    else
                        dispatch(setLoading(false))
                    
                },
                (error) => {
                    if (loadingDocOnly)
                    {
                        dispatch(setBrowsingDoc (null, null))
                        dispatch(setDocLoading(false))
                    }
                    else
                        dispatch(setLoading(false))
                    
                }
            )
    }
}

export function saveDocHighlight (textToSave, doc, projectId, withDocSave, offset, query) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID": doc.id, "textToSave": textToSave, "projectId":projectId, 'query':query, 'offset':offset, 'endpoint': 'saveDocHighlight' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return serverCommunication (conf, 
                        authRefresh({name:'saveDocHighlight', module:'actionsProject', params:arguments}), 
                        true,   
                        withDocSave ? getProjectParams(projectId, true) : setModifiedHighlightId(null))    
}

export function removeDocHighlight (highlightId, projectId, query) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "highlightId": highlightId, "projectId":projectId, 'query':query, 'endpoint': 'removeDocHighlight' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'removeDocHighlight', module:'actionsProject', params:arguments}), 
                        true,   
                        null) 
}

export function saveCommentForNewRange(selectionText, doc, projectId, withDocSave, commentText, color, query, notifyTeam) {
    const conf = {
        method: "post",
        body: JSON.stringify({ "docID": doc.id, "textToSave": selectionText, "projectId":projectId, "color": color, "notifyTeam":notifyTeam, 'endpoint': 'saveDocHighlight' }),
        headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return dispatch => {
        dispatch(setDocLoading(true))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => {
                if (res.status === 403)
                    dispatch(authRefresh({name:'saveCommentForNewRange', p1:selectionText, p2:doc, p3: projectId, p4: withDocSave, p5: commentText, p6: color, p7: query, p8:notifyTeam}))
                return res.json()})
            .then(
                (result) => {
                    if (result.savedHighlightId !== undefined) {

                        let highlightId = result.savedHighlightId;

                        const conf = {
                            method: "post",
                            body: JSON.stringify({ "highlightId": highlightId, "textToSave": commentText, 'query':query, "notifyTeam":notifyTeam, 'endpoint': 'saveComment'  }),
                            headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
                        };

                        fetch(config.get('globalUrl') + "platform/api", conf)
                            .then(res => {
                                if (res.status === 403)
                                    dispatch(authRefresh({name:'saveComment', p1:commentText, p2:highlightId, p3: query, p4:notifyTeam}))
                                return res.json()})
                            .then(
                                (result) => {
                                    if (withDocSave > 0)
                                        dispatch(getProjectParams(projectId, true))
                                    treatGenericResponse(result, dispatch)
                                    dispatch(setModifiedHighlightId(highlightId))
                                    dispatch(setDocLoading(false))
                                },
                                (error) => {
                                    dispatch(setDocLoading(false))
                                }
                            )
                    }
                    else {
                        if (withDocSave > 0)
                            dispatch(getProjectParams(projectId, true))
                        treatGenericResponse(result, dispatch)
                        dispatch(setDocLoading(false))
                    }
                },
                (error) => {
                    dispatch(setDocLoading(false))
                }
            )
    }
}

export function saveComment (textToSave, highlightId, query, notifyTeam) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "highlightId": highlightId, "textToSave": textToSave, 'query':query, "notifyTeam":notifyTeam, 'endpoint': 'saveComment' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'saveComment', module:'actionsProject', params:arguments}), 
                        true,   
                        setModifiedHighlightId(highlightId)) 
}

export function updateComment (highlightId, commentId, newText, query, notifyTeam) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "commentId": commentId, "newText": newText, 'query':query, "notifyTeam":notifyTeam, 'endpoint': 'editComment' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'updateComment', module:'actionsProject', params:arguments}), 
                        true,   
                        setModifiedCommentId(commentId)) 
}

export function setReadNotification (notificationId, read=true) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "notificationId": notificationId, "read": read, 'endpoint': 'setReadNotification'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'setReadNotification', module:'actionsProject', params:arguments}), 
                        true,   
                        null) 
}

export function removeComment (commentId, query) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "commentId": commentId, 'query':query, 'endpoint': 'removeComment' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'removeComment', module:'actionsProject', params:arguments}), 
                        true,   
                        null) 
}

export function onChangeDocState (doc, toSave, projectId, query=null, navigationParams={}) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID": doc.id, "toSave": toSave, "projectId":projectId, "query":query, "navigationParams":navigationParams, 'endpoint': 'alterDoc'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'onChangeDocState', module:'actionsProject', params:arguments}), 
                        true,   
                        getProjectParams(navigationParams.showingProjectId, true))
}

export function alterSavedDocOrder (projectId, docID, oldIndex, newOrder) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID": docID, "newOrder": newOrder, "projectId":projectId, 'endpoint': 'alterSavedDocOrder'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'alterSavedDocOrder', module:'actionsProject', params:arguments}), 
                        false,   
                        alterSavedDocOrderLocally(oldIndex, newOrder))
}

export function onChangeDocReadState (doc, read, projectId) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID": doc.id, "read": read, "projectId":projectId, 'endpoint': 'alterDocRead'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'onChangeDocReadState', module:'actionsProject', params:arguments}), 
                        true,   
                        getProjectParams(projectId, true))
}

export function loadDoc (docId, projectId, lang, searchQuery=null) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID": docId, 'projectId': projectId, "lang":lang, "query": searchQuery, 'endpoint': 'getDoc' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'loadDoc', module:'actionsProject', params:arguments}), 
                        true,   
                        null) 
    
}


export function getProjectParams (projectId, justSavedDoc, withFullHistory=false, withCompany=false) {
    const conf = {
      method: "post",
      body: JSON.stringify(withFullHistory ? { "projectId":projectId, "allLogs":true, 'endpoint': 'getProjectHistory' } : { "projectId":projectId, 'endpoint': 'getProjectHistory' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'getProjectParams', module:'actionsProject', params:arguments}), 
                        false,   
                        withCompany ? getCompany() : null) 
}

export function getSrcDoc (ref) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID":ref, 'endpoint': 'getDocSource' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    

    return dispatch => {
        dispatch(setDocLoading(true))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'getSrcDoc', module:'actionsProject', params:[ref]}))
                return res.blob()})
            .then((blob) => {
                var refComponents = ref.split ("/")
                var fname = refComponents[refComponents.length - 1].split (" ->")[0]
                
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fname);
                    dispatch(setDocLoading(false))
                } else { //other browsers
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fname);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    dispatch(setDocLoading(false))
                }
              })

    }
}

export function getProjectDoc (projectId, projectName, excludedDocs) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "projectId":projectId, "excludedDocs":excludedDocs, 'endpoint': 'getProjectDoc' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'getProjectDoc', module:'actionsProject', params:[projectId,projectName,excludedDocs]}))
                return res.blob()})
            .then((blob) => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, projectName + '.docx');
                    dispatch(setLoading(false))
                } else { //other browsers
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', projectName + '.docx');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    dispatch(setLoading(false))
                }
              })

    }
}


export function postSearch (query, projectId, startPosition, platformConfigs) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "searchQuery": query, "platformConfigs":platformConfigs, "projectId": projectId, "startPosition": startPosition, 'endpoint': 'detailedQuery'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "platform/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'postSearch', module:'actionsProject', params:[query, projectId, startPosition]}))
                return res.json()})
            .then(
                (result) => {
                    dispatch(setBrowsingDoc(null))
                    dispatch(onSearchResultsLoaded(result['result']))
                    //dispatch(onShowResults(true))
                    treatGenericResponse(result, dispatch)
                    dispatch(setLoading(false))
                    
                },
                (error) => {
                    dispatch(setLoading(false))
                }
            )
    }
}

export function findDocsByName (query) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "query": query, "endpoint": "findDocsByName"}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    
    return dispatch => {
        dispatch(onQSSearchQueryChanged(query))

        if ((query.length < 3))
            dispatch(onQSSearchResultsLoaded({ qsdocs:[], lang:null}))
        else
        {
            return fetch(config.get('globalUrl') + "platform/api", conf)
                .then(res => { 
                    if (res.status === 403) 
                        dispatch(authRefresh({name:'findDocsByName', module:'actionsProject', params:[query]}))
                    return res.json()})
                .then(
                    (result) => {
                        dispatch(onQSSearchResultsLoaded({ qsdocs:result.docs, lang:result.detectedLang}))
                        treatGenericResponse(result, dispatch)
                    },
                    (error) => {
                    }
                )
        }
    }
}


export function saveWatch (projectId, query, platformConfigs) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "projectId":projectId, "query": { "searchQuery": query, "platformConfigs":platformConfigs, "projectId": projectId}, 'endpoint': 'saveWatch' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return serverCommunication (conf, 
                        authRefresh({name:'saveWatch', module:'actionsProject', params:arguments}), 
                        true,   
                        null) 
    
}

export function removeWatch (watchId, projectId) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "watchId": watchId, "projectId":projectId, 'endpoint': 'removeWatch' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return serverCommunication (conf, 
                        authRefresh({name:'removeWatch', module:'actionsProject', params:arguments}), 
                        true,   
                        null) 
    
}