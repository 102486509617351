import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import '../css/NavHeaderHub.scss';
import logo from '../img/LogoE.png'


class NavHeader extends Component {
    state = {
        profileMenuShown: false,    
    };

    render()
    {
        return <Navbar className="navHub" bg="light" expand="lg">
            <Container fluid className="stdPadded">
                <Row>
                    <Col md={3}>
                        <Navbar.Brand href="https://eisphoria.com/"><img src={logo} className="logo" alt="logo" /></Navbar.Brand>
                    </Col>
                    <Col md={9} className="align-right">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/lib/competition/" active={this.props.lib === 'competition'}>Competition library</Nav.Link>
                            <Nav.Link href="/" active={this.props.lib !== 'competition'}>Data Hub</Nav.Link>
                            <Nav.Link href="https://eisphoria.com/news">News</Nav.Link>
                            <Nav.Link href="https://eisphoria.com/about">About us</Nav.Link>
                            <Nav.Link href="https://eisphoria.com/contact">Contact</Nav.Link>
                            <Nav.Link href="https://eisphoria.com/calendly" className="button">Request a demo</Nav.Link>
                            
                            
                            
                        </Nav>
                        </Navbar.Collapse>
                    </Col>
                    </Row>
            </Container>    
        </Navbar>

    }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authParams.loggedIn,
    username: state.authParams.username,
    platforms: state.platforms,
    interface: state.globalInterface
  }
}

export default connect(mapStateToProps)(NavHeader)
