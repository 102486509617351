import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom';
import history from '../utils/history';


import LoadingSpinner from "./LoadingSpinner"
import Popover from 'react-text-selection-popover';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown'
import Scrollbar from 'react-scrollbars-custom';
import { GlobalHotKeys } from "react-hotkeys";
import ReactTooltip from "react-tooltip"
//import highlightRenderer from '../HighlightsRenderer'
import {DocViewerEventFactory} from '../utils/DocViewerEventFactory'
import HighlightMenu from "./HighlightMenu";
import DocCommentDialog from "./DocCommentDialog";
import DocComments from "./DocComments";
import DocContentRenderer from "./DocContentRenderer";
import ProjectSelector from "./ProjectSelector"


import placeRightBelow from './DocViewerPopoverPositionStrategy'

import '../css/DocViewer.scss';

import { ReactComponent as DotsIcon} from '../img/ic_3_dots_un.svg'

import { ReactComponent as AddDocIcon} from '../img/ic_save.svg'
import { ReactComponent as DeleteIcon} from '../img/ic_delete_file.svg'
import { ReactComponent as PrintIcon} from '../img/ic_print.svg'

import { ReactComponent as HighlightIcon} from '../img/ic_marker.svg'
import { ReactComponent as SearchIcon} from '../img/ic_loupe_dots.svg'
import { ReactComponent as ChatIcon} from '../img/ic_chat.svg'
import { ReactComponent as CopyIcon} from '../img/ic_copy.svg'

import { ReactComponent as ImgHighlight} from "../img/ic_card-highlight.svg";
import { ReactComponent as ImgComment} from "../img/ic_card-comment.svg";


import { ReactComponent as TriangleIcon} from '../img/ic_triangle.svg'
//import { ReactComponent as TriangleIconHover} from '../img/nicon_triangle_active.svg'

import { ReactComponent as LinkIcon } from '../img/ic_out.svg'

import DocAnnotation from "./DocAnnotation";

import localConfig from '../config/config'
import globalConfig from '../configGlobal/config'

import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import GHelper from './GHelper'
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements,
    setURL
} from '../redux/actions'


import {
  onChangeDocState,
  loadDoc,
  setQuery,
  onShowQueryForm,
  setBrowsingDoc,
  saveDocHighlight,
  removeDocHighlight,
  getSrcDoc,
  setTranslationState,
  getProjectParams
} from '../redux/actionsProject'

class DocViewerGenPanel extends Component {
    constructorName = "DocViewer"
    state = {
        generatedText: "",
        waitingForResponse: false,
        shouldCancel: false
    };

    componentWillMount() {
        this.setState ({'generatedText':""})
    }
    
    render() {
        var that = this
        if (this.props.loadedDoc != null) {
            
            return (<div ref="docViewerGenPanel" className="docViewerGenPanel" onClick={(e) => { e.stopPropagation();}}> 
                            <Container fluid className="contentsContainer">
                                <Row >
                                    <Col md={12}>
                                    <button style={{opacity: that.state.waitingForResponse ? 0.5 : 1}} disabled={that.state.waitingForResponse} key="genPanel" className="text" onClick={async () => {
                                        that.setState ({generatedText: "", waitingForResponse: true})
                                        
                                        const response = await fetch('http://172.16.0.80:7123/generate_stream', {
                                            method: "post",
                                            body: JSON.stringify({ "inputs": "[INST]Generate the summary of the following text:\n" + that.props.loadedDoc.text + "[/INST]", 
                                                                "parameters":
                                                                    {
                                                                        "temperature" : 0.2,
                                                                        "top_p" : 0.9,
                                                                        "max_new_tokens":2000,
                                                                        "max_length": 32000,
                                                                        "max_tokens": 16000
                                                                    
                                                                } }),
                                            headers: new Headers({ "Content-Type": "application/json" })
                                          });
                                        console.log (that.props.loadedDoc)

                                        const reader = response.body.getReader();
                                        while (true) {
                                            const { value, done } = await reader.read();
                                            if (done || that.state.shouldCancel) 
                                            {
                                                that.setState ({waitingForResponse:false, shouldCancel:false})
                                                break;
                                            }
                                            try
                                            {
                                                let val = JSON.parse(new TextDecoder().decode(value).substring(5))
                                                //console.log(val);
                                                //document.getElementById ('outputContainer').innerHTML += val['token']['text']
                                                if (val['generated_text'])
                                                    that.setState ({generatedText:val['generated_text']})
                                                else
                                                    that.setState ({generatedText:that.state.generatedText + val['token']['text']})
                                            }
                                            catch (error) {
                                                console.error(error);
                                                // Expected output: ReferenceError: nonExistentFunction is not defined
                                                // (Note: the exact output may be browser-dependent)
                                              }
                                            
                                        }

                                    }}>Generate a summary</button>

                                    <button style={{opacity: that.state.waitingForResponse ? 0.5 : 1}} disabled={that.state.waitingForResponse} key="genPanel" className="text" onClick={async () => {
                                        that.setState ({generatedText: "", waitingForResponse: true})
                                        
                                        //let toTranslate = "1. Les primes qui seront versées en vertu des traités de réassurance seront fiscalement déductibles dans le chef de l’assureur sur pied de l’article 49 CIR92. 2. Les primes qui seront versées en vertu des traités de réassurance ne seront pas considérées dans le chef de l’assureur comme constitutif d’un avantage anormal ou bénévole octroyé au sens de l’article 26 CIR92."
                                        let toTranslate = that.props.loadedDoc.text.substring (0,2000)
                                        let body = JSON.stringify({ "inputs": "[INST]<<SYS>>\nOnly translate the user requests to English. Provide only the translation without any added comments.\n<</SYS>>\n\n" + toTranslate + "[/INST]", 
                                        "parameters":
                                            {
                                                "temperature" : 0.2,
                                                "top_p" : 0.9,
                                                "max_new_tokens":2000,
                                                "max_length": 32000,
                                                "max_tokens": 16000
                                            
                                        } })
                                        console.log (body)
                                        const response = await fetch('http://172.16.0.80:7123/generate_stream', {
                                            method: "post",
                                            body: body,
                                            headers: new Headers({ "Content-Type": "application/json" })
                                          });
                                        console.log (that.props.loadedDoc)

                                        const reader = response.body.getReader();
                                        console.log (123)
                                        while (true) {
                                            const { value, done } = await reader.read();
                                            console.log (1234, value, done)
                                            if (done || that.state.shouldCancel) 
                                            {
                                                that.setState ({waitingForResponse:false, shouldCancel:false})
                                                break;
                                            }
                                            try
                                            {
                                                let val = JSON.parse(new TextDecoder().decode(value).substring(5))
                                                console.log(val);
                                                //document.getElementById ('outputContainer').innerHTML += val['token']['text']
                                                if (val['generated_text'])
                                                    that.setState ({generatedText:val['generated_text']})
                                                else
                                                    that.setState ({generatedText:that.state.generatedText + val['token']['text']})
                                            }
                                            catch (error) {
                                                console.error(error);
                                                // Expected output: ReferenceError: nonExistentFunction is not defined
                                                // (Note: the exact output may be browser-dependent)
                                              }
                                        }

                                    }}>Translate the document</button>

<button style={{opacity: that.state.waitingForResponse ? 0.5 : 1}} disabled={that.state.waitingForResponse} key="genPanel" className="text" onClick={async () => {
                                        that.setState ({generatedText: "", waitingForResponse: true})
                                        
                                        const response = await fetch('http://172.16.0.80:7123/generate_stream', {
                                            method: "post",
                                            body: JSON.stringify({ "inputs": "[INST]Cite the detailed definition of the market explored in the following text:\n" + that.props.loadedDoc.text.substring (0,10000) + "[/INST]", 
                                                                "parameters":
                                                                    {
                                                                        "temperature" : 0.2,
                                                                        "top_p" : 0.9,
                                                                        "max_new_tokens":2000,
                                                                        "max_length": 32000,
                                                                        "max_tokens": 16000
                                                                    
                                                                } }),
                                            headers: new Headers({ "Content-Type": "application/json" })
                                          });
                                        console.log (that.props.loadedDoc)

                                        const reader = response.body.getReader();
                                        while (true) {
                                            const { value, done } = await reader.read();
                                            if (done || that.state.shouldCancel) 
                                            {
                                                that.setState ({waitingForResponse:false, shouldCancel:false})
                                                break;
                                            }
                                            try
                                            {
                                                let val = JSON.parse(new TextDecoder().decode(value).substring(5))
                                                //console.log(val);
                                                //document.getElementById ('outputContainer').innerHTML += val['token']['text']
                                                if (val['generated_text'])
                                                    that.setState ({generatedText:val['generated_text']})
                                                else
                                                    that.setState ({generatedText:that.state.generatedText + val['token']['text']})
                                            }
                                            catch (error) {
                                                console.error(error);
                                                // Expected output: ReferenceError: nonExistentFunction is not defined
                                                // (Note: the exact output may be browser-dependent)
                                              }
                                            
                                        }

                                    }}>Extract the market definition</button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        { that.state.waitingForResponse ? <div className='processingBox'>Processing... <a onClick={()=>{ that.setState ({'shouldCancel':true})}}>Cancel</a></div>:null}
                                        <div className='output' id='outputContainer'>
                                            {that.state.generatedText}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                    </div>
                );
        }
        else {
            return (
                    null
                );
        }
    }

}

function mapStateToProps(state) {
  return {
    loadedDoc: state.projects.loadedDoc,
    browsingDocId: state.projects.browsingDocId,
    browsingDocLang: state.projects.browsingDocLang,
    savedDocs: state.projects.savedDocs,
    isLoading: state.globalInterface.docIsLoading,
    query: state.query,
    refsCollection: state.projects.refsCollection,
    interface: state.globalInterface,
    username: state.authParams.username,
    project: state.projects.currentProject
  }
}

export default connect(mapStateToProps)(DocViewerGenPanel)

