import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'

import Notification from "../interfaceParts/Notification"

import LoginForm from "../interfaceParts/LoginForm"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import {
  checkLoggedIn,
  confirmMail,
  setLoginScreenType
} from '../redux/actionsAuth'

class LoginPage extends Component {
    componentDidMount() {
        this.props.dispatch(checkLoggedIn())
        if ((this.props.location.search.length > 60) && (!this.props.location.search.includes("utm_")))
            this.props.dispatch(confirmMail(this.props.location.search))
        if (this.props.location.search.includes("forget"))
            this.props.dispatch(setLoginScreenType('forget'))
        if (this.props.location.search.includes("register"))
            this.props.dispatch(setLoginScreenType('register'))
    }

    render() {
        if (this.props.loggedIn)
            return (<Redirect to='/' />)

        
        return (
            <div className="App">
                <LoginForm/>  
                <LoadingSpinner show={this.props.loading} />
                <Notification/>
            </div>
        );
  }
}

function mapStateToProps(state) {
    return {
        error: state.authParams.error,
        loggedIn: state.authParams.loggedIn,
        loading: state.globalInterface.loading,
        username: state.authParams.username

    }
}


export default connect(mapStateToProps)(LoginPage)


