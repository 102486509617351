import React, { Component } from "react";
import { connect } from 'react-redux'
import { Link} from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import { Button } from "react-bootstrap";
import { ReactComponent as SortImg} from '../img/ic_sort.svg'


import outLink from '../img/monitor/extLink.png'



import '../css/Monitor.scss';



import {
    getPlatforms,
    getPlatformDocs
    
} from '../redux/actionsMonitor'

import {
    checkLoggedIn,
    logout,
    redirectToLogin,
    getProfile,
    setLoginScreenType
  } from '../redux/actionsAuth'

  
class Monitor extends Component {

    state = {
        shownOptionsPanelID: null,
        sortByMenuShown: false,
        sortBy: "Latest",
    };

    componentWillMount() {
        this.props.dispatch (checkLoggedIn())
        this.props.dispatch (getPlatforms())
    }

    handleProposalChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleProposalCBChange = e => {
        this.setState({selectedProposals: Object.assign({}, this.state.selectedProposals, {
            [e.target.name]: e.target.checked
        })});
    };

    renderPlatformsForGroup (group) {
        var that = this
        var aPlatforms = this.props.platforms[group]
        if (!aPlatforms)
            aPlatforms = []
        var platforms = aPlatforms.map(function (d) { 
            return <div key={'platform_' + group + '_' + d.id} className={"sPlatformCard" + (that.props.browsingPlatform && (that.props.browsingPlatform.id === d.id) ? " active":"")} onClick={(e) => { that.props.dispatch(getPlatformDocs(d.id, that.props.browsingPeriod, that.props.browsingDecisions)); }}>
                {d.name}
                </div>
            }) 
        return <div className="platformsBlock">
            <h4>{group}</h4>
            {platforms}
        </div>

    }

    render(show) {
        var that = this

        var rPart = null
        if (that.props.browsingPlatform)
        {
            var selectors = [["Last 3 months", "3M"], ["Last month", "1M"], ["Last 2 weeks", "14D"], ["Last 5 days", "5D"], ["Last 2 days", "2D"]].map (function (d) {
                return <div key={"selector" + d[1]} className={"delaySelector" + (that.props.browsingPeriod === d[1] ? " active":"")} onClick={(e)=>{ that.props.dispatch(getPlatformDocs(that.props.browsingPlatform.id, d[1], that.props.browsingDecisions)); }}>{d[0]}</div>
            })

            if (that.props.browsingDocs)
            {
                if (this.state.sortBy === 'Name')
                    that.props.browsingDocs.sort((a,b) => (a.caption > b.caption) ? 1 : -1);
                else if (this.state.sortBy === 'Latest')
                    that.props.browsingDocs.sort((a,b) => (a.dateString > b.dateString) ? -1 : 1);
                else if (this.state.sortBy === 'Oldest')
                    that.props.browsingDocs.sort((a,b) => (a.dateString > b.dateString) ? 1 : -1);
            }    

            var docCards = that.props.browsingDocs.map (function (d) {
                return <div key={'doccard_' + d.id} className="docCard">
                    <h5>{that.props.browsingPlatform.name}</h5>
                    <h4>{d.caption}</h4>
                    <h6>{d.language}</h6>
                    <span className="buttons">
                        {d.link ? <a className="button" href={d.link} target="_blank">Document <img src={outLink} className="outlink"/></a>:null}
                        {d.caseURL ? <a className="button" href={d.caseURL} target="_blank">Case <img src={outLink} className="outlink"/></a>:null}
                    </span>
                </div>
            })

            rPart = <div className="rPart">
                <Dropdown drop={"down"} className="filterDropdown">
                    <Dropdown.Toggle variant="light" className="sortByBtn">
                        <span className="weightRegular"><SortImg/></span> {this.state.sortBy}
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight="true" flip={false} className="gvOptionsMenu optionsMenu withShadow">
                        {['Latest', 'Oldest', 'Name'].map(function (d) { return <button key={'sortBy_' + d} className={that.state.sortBy === d ? 'active' : ''} onClick={(e) => { that.setState ({sortBy:d, sortByMenuShown:false, shownOptionsPanelID:null})}}>{d}</button> }) }
                    </Dropdown.Menu>
                </Dropdown>
                
                <div className="delaySelectors">Period {selectors}</div>
                <Scrollbar noScrollX={true}>
                        <Container fluid className="cont">
                            <div className="docCounter">{(that.props.browsingDocs.length > 0) ? (that.props.browsingDocs.length + ((that.props.browsingDocs.length % 10 === 1) ? " result":" results") + " found"):"No results found"}</div>
                            {docCards}
                            <div className="footer">© EisphorIA 2022 | <a target="_blank" href="https://www.eisphoria.com/policies/terms/">Terms & Conditions</a></div>
                        </Container>   
                    </Scrollbar>
            </div>
        }
        
        return (
            
        <div className="PlatformSelector">
            <div className="fullSize" >
                <div className="lPart">
                    <Scrollbar noScrollX={true}>
                        <Container fluid className="cont">
                            <h3>Public sources</h3>
                            <div className="platformsContainer">
                                {this.renderPlatformsForGroup ('Europe')}
                            </div>
                            <div className="platformsContainer">
                                {this.renderPlatformsForGroup ('Nationals')}
                            </div>
                            { that.props.browsingPlatform && that.props.browsingPlatform.hasDecisionsSplit ?
                                <div className="decisionsSplitController">
                                    <h3>Document type</h3>
                                    <div className="controls">
                                        <button className={that.props.browsingDecisions ? "active":""}  onClick={(e)=>{ that.props.dispatch(getPlatformDocs(that.props.browsingPlatform.id, that.props.browsingPeriod, true)); }}>Decisions</button><button className={that.props.browsingDecisions ? "":"active"}  onClick={(e)=>{ that.props.dispatch(getPlatformDocs(that.props.browsingPlatform.id, that.props.browsingPeriod, false)); }}>Others</button>
                                    </div>
                                </div>: null}
                        </Container>   
                        
                    </Scrollbar>
                </div>
                {rPart}
            </div>
            

        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.globalInterface.loading,
        platforms: state.monitor.platforms,
        logs: state.platforms.logs,
        browsingPlatform: state.monitor.browsingPlatform,
        browsingPeriod: state.monitor.browsingPeriod,
        browsingDocs: state.monitor.browsingDocs,
        browsingDecisions: state.monitor.browsingDecisions,
        username: state.authParams.username,
        loggedIn: state.authParams.loggedIn,
        
    }
}

export default connect(mapStateToProps)(Monitor)


