import React, { Component } from 'react';
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import zxcvbn from 'zxcvbn'

import '../css/NavHeader.scss';
import logo from '../img/LogoE.png'
import imec from '../img/imec.svg'
import promoImg from '../img/promoImg.png'



import '../css/LoginForm.scss';

import {
    resetPass
  } from '../redux/actionsAuth'
  


class ResetPass extends Component
{
    state = {
        password:"",
        cpassword:"",
        pScore: {score:0}
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
      };

    handleSubmit = e => {
        e.preventDefault();    
        this.props.dispatch(resetPass (this.state.password, this.props.secKey))
        
    }

    render() {
        var passFilled = this.state.password && (this.state.password.length > 5) && this.state.cpassword && (this.state.password === this.state.cpassword)
        
        return (
            <div className={"login " + this.state.mode}>
                <Navbar bg="light" expand="lg">
                    <Container fluid className="stdPadded">
                        <Row>
                            <Col md={3}>
                                <Navbar.Brand href="/"><img src={logo} className="logo" alt="logo" /></Navbar.Brand>
                            </Col>
                            <Col md={9} className="align-right">
                                
                            </Col>
                            </Row>
                    </Container>    
                </Navbar>
                <div className="loginForm">
                        <div className="contentsContainer"><div className="contents">
                            <h3>Reset password at EisphorIA</h3>
                            <div className="vspace"></div>
                            
                            
                            <div>
                                <form method="post" onSubmit={this.handleSubmit}>
                                    <div className="field">
                                        <div className="control withFloatingLabel">
                                            <input
                                                className="input"
                                                name="password"
                                                required
                                                onChange={this.handleInputChange}
                                                value={this.state.password}
                                                type="password"
                                            />
                                            <span className="floating-label">Password</span>
                                            <span className={"passwordStrength ps" + this.state.pScore.score}><span className="p1"><span/></span><span className="p2"><span/></span><span className="p3"><span/></span><span className="p4"><span/></span></span>
                                            {this.state.pScore.feedback && this.state.pScore.feedback.warning ? <span className="passwordHint">{this.state.pScore.feedback.warning}</span> : null}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control withFloatingLabel">
                                            <input
                                                className="input"
                                                name="cpassword"
                                                required
                                                onChange={this.handleInputChange}
                                                value={this.state.cpassword}
                                                type="password"
                                            />
                                            <span className="floating-label">Confirm Password</span>
                                        </div>
                                    </div>

                                    <div className="control">
                                        <button type="submit"   className={"button is-info" + (passFilled ? "" : " disabled")} disabled={!passFilled} >Reset</button>
                                    </div>

                                    
                                </form>

                             
                            </div>
                        </div></div>
                    
                </div>
            </div>
            
        );
    }

}

function mapStateToProps(state) {
    return {
        loading: state.globalInterface.loading,
        
    }
}

export default connect(mapStateToProps)(ResetPass)
