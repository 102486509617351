import config from 'react-global-configuration'

import {
    setLoading,
    setNotification,
    getAccessToken,
    alterFields
} from './actions'

import {
    authRefresh,
    onLoadProfile
} from './actionsAuth'

export const PLATFORMS_LOADED = 'PLATFORMS_LOADED'
export const SUBSCRIBE = "SUBSCRIBE"
export const ALTER_PLATFORM_STATUS = "ALTER_PLATFORM_STATUS"

export function alterPlatformStatus (platform, status)
{
    return {
        type: ALTER_PLATFORM_STATUS,
        platform,
        status
    }
}

export function subscribe (platform) {
    return {
        type: SUBSCRIBE,
        platform
    }
}

export function onLoadPlatforms (platforms) {
    return {
        type: PLATFORMS_LOADED,
        platforms
    }
}

export function treatGenericResponse (response, dispatch) {
    if (response.error && (response.error.length > 0))
        dispatch(setNotification ("Error occured.", response.error))
    if (response.platforms)
        dispatch(onLoadPlatforms(response.platforms))
    if (response.profile)
        dispatch(onLoadProfile(response.profile))
}


export function serverCommunication (endpoint, conf, expiredCallback, extraExecOnSuccess) {
    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + endpoint, conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(expiredCallback)
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if ((!result.error) && (extraExecOnSuccess))
                        for (var toDispatch of extraExecOnSuccess)
                            dispatch(toDispatch)
                    dispatch(setLoading(false))
                    
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log (error)
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")
                    
                }
            )
    }
}



export function sendSurveyAnswer (answer) {
    var aT = getAccessToken ()
    var cUrl = aT ? "api/sendSurvey" : "api/anonymous/sendSurvey"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ "answer": answer }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
                                authRefresh({name:"sendSurveyAnswer", module:"actionsPlatforms", params:arguments}), 
                                [setNotification ("Thanks for sharing", "Your input allows us to prioritize the right libraries", "popup", "info")])
}

export function getPlatforms () {
    var aT = getAccessToken ()
    var cUrl = aT ? "api/platforms" : "api/anonymous/platforms"
    const conf = {
      method: "get",
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatforms", module:"actionsPlatforms", params:arguments}), 
        [])
}

export function getPaymentIntentND (platform, paymentType) {
    var aT = getAccessToken ()
    var cUrl = "api/paymentIntent"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ "pid": platform.id, paymentType:paymentType }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return fetch(config.get('globalUrl') + cUrl, conf).then(res => { return res.json()})
}

export function getSubscribeSessionND (platform, paymentType) {
    var aT = getAccessToken ()
    var cUrl = "api/subscribe"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ "pid": platform.id, paymentType:paymentType }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return fetch(config.get('globalUrl') + cUrl, conf).then(res => { return res.json()})
}


export function startTrial (platform, withNotification=true) {
    var aT = getAccessToken ()
    var cUrl = "api/startTrial"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ "pid": platform.id }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatforms", module:"actionsPlatforms", params:arguments}), 
        withNotification ? [setNotification ("Trial activated", "You can go to the platform", "popup", "info")]:[])
}

export function cancelSubscription (subscription) {
    var aT = getAccessToken ()
    var cUrl = "api/cancelSubscription"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ "cplid": subscription.id }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatforms", module:"actionsPlatforms", params:arguments}), 
        [setNotification ("Subscription cancelled", "Your license will not be renewed", "popup", "info")])
}


export function getProfile () {
    var aT = getAccessToken ()
    if (!aT) {
        return dispatch => {}
    }
    var cUrl = "api/profile"
    const conf = {
      method: "get",
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatforms", module:"actionsPlatforms", params:arguments}), 
        [])
}

export function alterProfile (changes, password=false) {
    var aT = getAccessToken ()
    var cUrl = "api/alterProfile"
    const conf = {
      method: "post",
      body: JSON.stringify({ profile: changes, password:password }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + aT })
    };

    return serverCommunication (cUrl, conf, 
        authRefresh({name:"getPlatforms", module:"actionsPlatforms", params:arguments}), 
        [setNotification("Settings saved", "", "bottom", "info"), alterFields(null)])
}