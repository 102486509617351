import React, { Component } from "react";
import { connect } from 'react-redux'
import { Link} from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom';
import { Redirect } from 'react-router-dom'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/PlatformSelector.scss';


import {
    getProfile,
} from '../redux/actionsPlatforms'

import {
    checkLoggedIn,
    redirectToLogin,
    
    setLoginScreenType
  } from '../redux/actionsAuth'

  
class InitialRouter extends Component {

    state = {
        
    };

    componentWillMount() {
        this.props.dispatch (checkLoggedIn())
        this.props.dispatch (getProfile())
    }

    
    render(show) {
        var that = this
        if (this.props.profile.hasSubscriptions)
        {
            if (this.props.profile.hasV2)
                return <Redirect to={"/v2/project/my"} />
            else
                return <Redirect to={"/projects/"} />
        }
        


        
        return (
        
        
        <div className="PlatformSelector">
            {(that.props.loggedIn && that.props.profile && that.props.profile.hasImbrication && that.props.profile.hasSubscriptions) ? 
                <a href="/projects/" className="button goToPlatformButton">Go to platform →</a>
                : null}
            

            <div className="fullSize" >
                <Scrollbar noScrollX={true}>
                    <Container fluid className="stdPadded subheader">
                        <Row>
                                <Col md={7} className="generalTitle">
                                    <h2>One single platform to investigate competition law decisions</h2>
                                    <p className="subh">
                                    EisphorIA centralizes competition law information (decisions and related documents) from European and national competition authorities' web sites (after receiving their authorization).  Our algorithms analyze the entire content  of documents and provide you a powerful search experience combining contextual search and targeting factors.   In a fraction of seconds, EisphorIA identifies passages of documents expressing a similar context to your search query.  And this across all the authorities present in the database.  In other words, one central place and one powerful search to help you find the critical information! </p>
                                    <span className="buttons">
                                        {(that.props.loggedIn && that.props.profile && that.props.profile.hasImbrication && that.props.profile.hasSubscriptions) ? 
                                            <a href="/projects/" className="ctaBtn button">Go to platform →</a>
                                            : null}
                                        {(that.props.loggedIn && that.props.profile && !that.props.profile.hasSubscriptions) ? 
                                            <button className="ctaBtn" onClick={(e)=>{ that.setState ({showingSelectorPopup: true}) }}>Start your free trial</button>:null}

                                        {(!that.props.loggedIn) ? 
                                            <button className="ctaBtn" onClick={(e)=>{ that.props.dispatch(setLoginScreenType('register')); that.props.dispatch(redirectToLogin()) }}>Start your free trial</button>:null}

                                        <a href="https://eisphoria.com/calendly" className="lightBtn button">Schedule a demo</a>
                                    </span>
                                </Col>
                                
                            
                        </Row>
                    </Container>
                    
                    
                    
                    
                    <Container fluid className="stdPadded stdTopPadded contactUs">
                        <Row>
                            <Col md={12}>
                                <h3>Contact us!</h3>
                                <a className="mailBtn" href="mailto:start@eisphoria.com">start@eisphoria.com</a>
                                <a className="demoBtn btn btn-dark my-2 py-1 px-3 rounded" href="https://www.eisphoria.com/calendly">Request a demo</a>
                            </Col>
                        </Row>
                    </Container>

                    <footer className="footer mt-auto">
                        <Container>
                            <Row className="align-items-center">
                                <Col md={4}>
                                    Copyright © EisphorIA 2022<br/>
                                    <a href="https://www.eisphoria.com/policies/cookie-policy/">Cookies policy</a><br/>
                                    <a href="https://www.eisphoria.com/policies/privacy-policy/">Privacy policy</a><br/>
                                    <a href="https://www.eisphoria.com/policies/terms/">Terms and conditions</a><br/>
                                </Col>
                                <Col md={4} className="text-center">
                                    <a href="tel:+32.2.881.01.09">+32.2.881.01.09</a><br/>
                                    <a href="mailto:start@eisphoria.com">start@eisphoria.com</a>
                                </Col>
                                <Col md={4} className="text-right">
                                    <a className="btn btn-social mx-1" href="https://twitter.com/eisphoria"><i className="fab fa-fw fa-twitter"></i></a>
                                    <a className="btn btn-social mx-1" href="https://www.linkedin.com/company/eisphoria/"><i className="fab fa-fw fa-linkedin-in"></i></a>
                                </Col>
                                
                            </Row>
                        </Container>
                    </footer>               
                </Scrollbar>
            </div>
            

        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.globalInterface.loading,
        username: state.authParams.username,
        interface: state.globalInterface,
        loggedIn: state.authParams.loggedIn,
        profile: state.authParams.profile
    }
}

export default connect(mapStateToProps)(InitialRouter)


