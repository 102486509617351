import config from 'react-global-configuration'
import Cookies from 'universal-cookie'

import {
    setLoading,
    setNotification,
    getAccessToken
} from './actions'

import * as actionsPlatforms from './actionsPlatforms'
import * as actionsProject from './actionsProject'
import * as actionsProjects from './actionsProjects'
import * as actionsMonitor from './actionsMonitor'

export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOG_OUT = 'LOG_OUT'
export const CHECK_LOGGED_IN = 'CHECK_LOGGED_IN'
export const ASSERT_LOGGED_IN = 'ASSERT_LOGGED_IN'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const GET_TOKEN = 'GET_TOKEN'
export const SHOULD_REDIRECT_TO_LOGIN = 'SHOULD_REDIRECT_TO_LOGIN'

export const ON_LOAD_PROFILE = 'ON_LOAD_PROFILE'
export const SET_LOGIN_SCREEN_TYPE = 'SET_LOGIN_SCREEN_TYPE'

export const SHOULD_REDIRECT_TO_PROJECTS = 'SHOULD_REDIRECT_TO_PROJECTS'

export function redirectToProjects () {
    return {
        type: SHOULD_REDIRECT_TO_PROJECTS
    }
}


export function onLoadProfile (profile) {
    return {
        type: ON_LOAD_PROFILE,
        profile
    }
}

export function redirectToLogin () {
    return {
        type: SHOULD_REDIRECT_TO_LOGIN
    }
}

export function setLoginScreenType (screenType) {
    return {
        type: SET_LOGIN_SCREEN_TYPE,
        screenType
    }
}

export function loggedIn (username, auth) {
    return {
        type: LOGGED_IN,
        username,
        auth
    }
}

export function setLogout () {
    return {
        type: LOG_OUT
    }
}

export function refreshedToken (auth) {
    return {
        type: REFRESH_TOKEN,
        auth
    }
}


export function loginFailed () {
    return {
        type: LOGIN_FAILED
    }
}

export function checkLoggedIn () {
    return {
        type: CHECK_LOGGED_IN
    }
}

export function assertLoggedIn () {
    return {
        type: ASSERT_LOGGED_IN
    }
}

export function logout () {
    var mAuth = {
        clientId: 'XQOmCxEs4EDfNTQcOFwHSnAY3QkcksqNFDS1bq89',
        clientSecret: 'EHHMPTfpjjT2qi7Wq2LaZ373a7htWxJj13XkPzPeM19vYoMyg89WXN66CbfJQ5vyrn9UHX6NtNpNWfhtwVN31mCDsWmybFHiffa1qef9aLfgmWWGCaTA8AeJEr6km5nJ',
        accessTokenUri: config.get('globalUrl') + 'o/revoke_token/',
    }

    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("token", getAccessToken())

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(mAuth.accessTokenUri, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    dispatch(setLogout())
                    dispatch(setLoading(false))
                    
                },
                (error) => {
                    console.log ("error")
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
                    
                }
            )
    }
}


export function auth (username, pass) {
    var mAuth = {
        clientId: 'XQOmCxEs4EDfNTQcOFwHSnAY3QkcksqNFDS1bq89',
        clientSecret: 'EHHMPTfpjjT2qi7Wq2LaZ373a7htWxJj13XkPzPeM19vYoMyg89WXN66CbfJQ5vyrn9UHX6NtNpNWfhtwVN31mCDsWmybFHiffa1qef9aLfgmWWGCaTA8AeJEr6km5nJ',
        accessTokenUri: config.get('globalUrl') + 'o/token/',
    }

    var cUser = username.toLowerCase()
    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("grant_type", "password")
    data.append("username", cUser)
    data.append("password", pass)

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(mAuth.accessTokenUri, conf)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.error)
                    {
                        dispatch(loginFailed())
                        dispatch(setLoading(false))
                        dispatch(setNotification ("Error occured.", "The combination of username and password is not correct"))
                    }
                    else
                    {
                        dispatch(loggedIn(cUser, result))
                        dispatch(setLoading(false))
                    }
                },
                (error) => {
                    console.log (error)
                    dispatch(loginFailed())
                    dispatch(setLoading(false))
                    dispatch(setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com"))
                    
                }
            )
    }

}
export function refreshCallback (dispatch, desiredAction)
{
    switch (desiredAction.module) {
        case 'actionsPlatforms':
            dispatch(actionsPlatforms[desiredAction.name](...desiredAction.params))
            break
        case 'actionsProject':
            dispatch(actionsProject[desiredAction.name](...desiredAction.params))
            break
        case 'actionsProjects':
            dispatch(actionsProjects[desiredAction.name](...desiredAction.params))
            break
        case 'actionsMonitor':
            dispatch(actionsMonitor[desiredAction.name](...desiredAction.params))
            break
        
        default:
            console.log ('Unknown callback action: ' + desiredAction.name + 'in module ' + desiredAction.module)
    }
}

export function authRefresh (desiredAction) {
    const cookies = new Cookies()
    var vauth = cookies.get("sysauth")
    var r_token = ""
    if ((vauth !== null) && (vauth !== undefined))
        r_token = vauth.refresh_token
    var mAuth = {
        clientId: 'XQOmCxEs4EDfNTQcOFwHSnAY3QkcksqNFDS1bq89',
        clientSecret: 'EHHMPTfpjjT2qi7Wq2LaZ373a7htWxJj13XkPzPeM19vYoMyg89WXN66CbfJQ5vyrn9UHX6NtNpNWfhtwVN31mCDsWmybFHiffa1qef9aLfgmWWGCaTA8AeJEr6km5nJ',
        accessTokenUri: config.get('globalUrl') + 'o/token/',
    }

    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("grant_type", "refresh_token")
    data.append("refresh_token", r_token)

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    return dispatch => {
        dispatch(setLoading(true))
        fetch(mAuth.accessTokenUri, conf)
            .then(
                res => { 
                    if (res.status !== 200) 
                    {
                        dispatch(loginFailed())
                        dispatch(setLoading(false))
                        dispatch(setNotification ("Error occured.", "Need to login"))
                    }
                    return res.json()})
            .then(
                (result) => {
                    if (result.error)
                    {
                        dispatch(loginFailed())
                        dispatch(setLoading(false))
                        dispatch(setNotification ("Error occured.", result.error))
                    }
                    else {
                        dispatch(refreshedToken(result))
                        refreshCallback (dispatch, desiredAction)
                    }
                },
                (error) => {
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")
                }
            )
    }
}

export function forgetPass (mail) {
    var cUrl = "api/forgetPass"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ params: {mail: mail }}),
      headers: new Headers({ "Content-Type": "application/json" })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + cUrl, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    if (result.status === "ok")
                        dispatch(setNotification ("Check e-mail for further instructions", "If the e-mail is not in your inbox, please check the spam folder", "popup", "info"))
                    else
                        dispatch(setNotification ("Error occured.", result.error))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")
                }
            )
    }
}

export function resetPass (newPass, searchPath) {
    var cUrl = "api/changePass"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ newPass: newPass, confirmationString: searchPath}),
      headers: new Headers({ "Content-Type": "application/json" })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + cUrl, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    if (result.status === "ok")
                    {
                        dispatch(redirectToLogin())
                        dispatch(setNotification ("Your password has changed", "You can login to the system", "popup", "info"))
                    }
                    else
                        dispatch(setNotification ("Error occured.", result.error))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")
                }
            )
    }
}

export function register (params) {
    var cUrl = "api/register"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ params: params }),
      headers: new Headers({ "Content-Type": "application/json" })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + cUrl, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    dispatch(setLoading(false))
                    if (result.status === "ok")
                    {
                        //if (result.whitelisted) 
                            dispatch(setNotification ("Check your mailbox", "You need to confirm your registration by clicking the link in the message we have sent to your e-mail address. If you don't find it in your inbox, don't hesitate to check the spam folder", "popup", "info"))
                        //else
                        //    dispatch(setNotification ("We are reviewing your account", "After your lawyer profile is confirmed, we will reach you by e-mail to confirm you can access the system", "popup", "info"))
                    }
                    else
                        dispatch(setNotification ("Error occured.", result.error))
                        
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")         
                }
            )
    }
}

export function confirmMail (confirmationLink) {
    var cUrl = "api/confirmEmail"
    
    const conf = {
      method: "post",
      body: JSON.stringify({ confirmationString: confirmationLink }),
      headers: new Headers({ "Content-Type": "application/json" })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + cUrl, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    dispatch(setLoading(false))
                    if (result.status === "enabled")
                        dispatch(setNotification ("Email confirmed", "Now you can login to the system", "popup", "info"))
                    else if (result.status === "waiting")
                        dispatch(setNotification ("Waiting approval", "Your e-mail is confirmed. As our service is aimed for legal professionals only, our team is verifying your company, we will come back to you extremely soon!", "popup", "info"))
                    else
                        dispatch(setNotification ("Error occured.", result.error))
                    
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                    setNotification ("Error occured.", "Please try again or contact us directly at support@eisphoria.com")
                }
            )
    }

}


