import React, { Component } from "react";
import { connect } from 'react-redux'
import config from 'react-global-configuration'

import {Dropdown} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GlobalHotKeys } from "react-hotkeys";
import getCaretCoordinates from "textarea-caret";
import TextareaAutosize from 'react-textarea-autosize';
import Scrollbar from 'react-scrollbars-custom';
import { WithContext as ReactTags } from 'react-tag-input';

import MIHelper from './MIHelper'


import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import GHelper from './GHelper'
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements,
    setPlatformDisabled
} from '../redux/actions'
  
import globalConfig from '../configGlobal/config'

import { ReactComponent as SearchImg} from '../img/ic_search_active.svg'
import { ReactComponent as BellImg} from '../img/ic_bell.svg'

import '../css/QueryForm.scss';

import {
  setQueryLanguage,
  setQuery,
  changeQuery,
  addSelection,
  removeSelection,
  setTranslationState,
  findDocsByName,
  setBrowsingDoc,
  onShowQueryForm,
  setChangedFilters,
  setDatesFilter,
  setCDatesFilter,
  shouldPostSearch,
  saveWatch,
  setStartPosition
} from '../redux/actionsProject'

import {
    setShouldShowMustIncludePopup,
    dismissHelper
} from '../redux/actionsProjects'

import {
    setMIHelper
} from '../redux/actions'

const KeyCodes = {
    comma: 188,
    enter: 13,
  };
const tagsDelimiters = [KeyCodes.comma, KeyCodes.enter];

class QueryForm extends Component {
    constructorName = "QueryForm"
    state = {
        selectionsPanelVisible: false,
        quickEditY: 20,
        quickEditX: 20,
        qsShowMore: false,
        watchListVisible: false,
        canSaveWatch: true,
        currentQText: "",
        qTextJustUpdated: false,
        cfilters: {minCDate:'', maxCDate:'', minDate:'', maxDate:'', 
                    minCDateTS:null, maxCDateTS:null, minDateTS:null, maxDateTS:null},
        minimized: false,
        expandedFilters: {},
        filtersPanelVisible: false
    };

    hotKeysMap = {
        SEARCH: ["ctrl+enter","command+enter"],
    }
    

    hotKeysHandlers = {
        SEARCH: (e) => { e.preventDefault(); this.handleSubmit(e) },
    }

    checkQueryAndAdaptInterface () {
        if (this.props.query)
        {
            if (this.props.projects.qsquery !== this.props.query.query)
                this.props.dispatch (findDocsByName(this.props.query.query))
        }
        else
            this.props.dispatch (findDocsByName(""))
    }

    componentDidMount() {
        this.joyrideRef = React.createRef()
        this.checkQueryAndAdaptInterface ()
        this.checkSelectionsPanelVisible()
        if (this.props.query.query !== this.state.currentQText)
            this.setState ({currentQText: this.props.query.query})
        if (this.props.query)
            this.setState ({cfilters: { minCDate: this.props.query.minCDate ?  this.timestampToCleanDate(this.props.query.minCDate) : '', 
                                        maxCDate: this.props.query.maxCDate ?  this.timestampToCleanDate(this.props.query.maxCDate) : '', 
                                        minDate: this.props.query.minDate ?  this.timestampToCleanDate(this.props.query.minDate) : '', 
                                        maxDate: this.props.query.maxDate ?  this.timestampToCleanDate(this.props.query.maxDate) : ''}})
    }

    checkSelectionsPanelVisible = e => {
        const taSelection = this.textAreaRawSelectedText ()
        const wSelection = this.getWindowSelectionText()
        var pVisible = (taSelection.length > 0) && (taSelection === wSelection)
        this.setState ({selectionsPanelVisible: pVisible})
    }

  textAreaSelectedText = e=> 
  {
    var textComponent = document.getElementById('textareaID');
    var selectedText;
    var startPos = 0
    if (textComponent.selectionStart !== undefined)
    {// Standards Compliant Version
      startPos = textComponent.selectionStart;
      while ((startPos > 0) && (/[’'a-zA-Z0-9]/.test( textComponent.value[startPos])))
        startPos -= 1
      var endPos = textComponent.selectionEnd;
      while ((endPos < textComponent.value.length) && (/[’'a-zA-Z0-9]/.test( textComponent.value[endPos - 1])))
        endPos += 1
      selectedText = textComponent.value.substring(startPos, endPos);
    }
    else if (document.selection !== undefined)
    {// IE Version
      textComponent.focus();
      var sel = document.selection.createRange();
      selectedText = sel.text;
      startPos = this.props.query.query.iundexOf(selectedText)
    }

    return [selectedText, startPos]
  }

  getWindowSelectionText = e => {
    var text = "";
    var activeEl = document.activeElement;
    var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
    if (
      (activeElTagName === "textarea") || ((activeElTagName === "input" &&
      /^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
      (typeof activeEl.selectionStart == "number"))
    ) {
        text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
    } else if (window.getSelection) {
        text = window.getSelection().toString();
    }
    return text;
    }

  textAreaRawSelectedText = e=> 
  {
    var textComponent = document.getElementById('textareaID');
    
    var selectedText;

    if (textComponent.selectionStart !== undefined)
    {// Standards Compliant Version
        var startPos = textComponent.selectionStart;
        var endPos = textComponent.selectionEnd;
        selectedText = textComponent.value.substring(startPos, endPos);
        
      
    }
    else if (document.selection !== undefined)
    {// IE Version
        textComponent.focus();
        var sel = document.selection.createRange();
        selectedText = sel.text;
    }

    var coordinates = getCaretCoordinates(textComponent, textComponent.selectionEnd);
    this.setState ({quickEditY:coordinates.top + coordinates.height - textComponent.scrollTop, quickEditX:coordinates.left})

    return selectedText
  }

  handleSelectionClick = selectionType => {
    var selection = this.textAreaSelectedText ()
    var text = selection[0]
    if (text.length < 1) {
      alert ('Please select some text in the query you have entered');
      return;
    }
    this.props.dispatch (addSelection (selectionType, text, selection[1]))
    this.setState ({selectionsPanelVisible:false, canSaveWatch: true})
  };


  handleSelect = e => {
    this.checkSelectionsPanelVisible ()
  };

  componentDidUpdate(prevProps, prevState){   
    if ((this.props.query.query !== this.state.currentQText) && ! this.state.qTextJustUpdated)
        this.setState ({currentQText: this.props.query.query})
    if ((this.props.query.query === this.state.currentQText) && this.state.qTextJustUpdated)
        this.setState ({qTextJustUpdated: false})
    this.syncDateValues(prevState)
}
  
  handleChange = e => {
    if (e.target.name === 'query')
    {
        this.setState ({canSaveWatch: true, currentQText:e.target.value, minimized:false, qTextJustUpdated:true})
        this.props.dispatch (changeQuery(e.target.value))
        this.checkQueryAndAdaptInterface ()
        if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName] === 0) && (e.target.value.length > 0)) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
        
    }
  };

  handleSubmit = e => {
        if (e)
        {
            e.preventDefault();
            e.stopPropagation()
        }
        var shouldReallyPostSearch = true

        if (this.props.projects.company.shouldShowMustIncludePopup === 1)
        {
            if ((this.props.query.selectionMust.length > 0))
            {
                this.props.dispatch(setShouldShowMustIncludePopup (-1));
                this.props.dispatch(dismissHelper({id:-1}));
            }
            else
            {
                this.props.dispatch (setMIHelper(true))
                shouldReallyPostSearch = false
            }
        }
        if (this.props.projects.company.shouldShowMustIncludePopup === 0)
            this.props.dispatch(setShouldShowMustIncludePopup (1))
        
        if (shouldReallyPostSearch)
        {
            this.props.dispatch (setStartPosition(0))
            this.props.dispatch (shouldPostSearch(true))
            this.setState ({minimized:true})
            //if (this.props.query.query.split(" ").length > 12)
            //  this.props.dispatch (postSearch(this.props.query, this.props.projectId))

            if ((this.constructorName in this.props.interface.tutorialActiveElements) && ((this.props.interface.tutorialActiveElements[this.constructorName] === 3) || (this.props.interface.tutorialActiveElements[this.constructorName] === 4))) 
                this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
            //else if (window.confirm('You have put a very short description. If you complete it further, you will get better results! Do you want to continue as it is now?'))
            //  this.props.dispatch (postSearch(this.props.query, this.props.projectId))
        }
  };


  equalQueries (q1, q2)
  {
        if (q1.query !== q2.query)
            return false
        
        if (q1.selectionMust || q2.selectionMust)
        {   
            if (!q1.selectionMust || !q2.selectionMust)
                return false
            if (q1.selectionMust.length !== q2.selectionMust.length)
                return false
            for (var i = 0; i < q1.selectionMust.length; i++)
                if (!q2.selectionMust.includes(q1.selectionMust[i]))
                    return false
        }
        if (q1.selectionExclude || q2.selectionExclude)
        {   
            if (!q1.selectionExclude || !q2.selectionExclude)
                return false
            if (q1.selectionExclude.length !== q2.selectionExclude.length)
                return false
            for (var i = 0; i < q1.selectionExclude.length; i++)
                if (!q2.selectionMust.includes(q1.selectionExclude[i]))
                    return false
        }
        return true
  }

    handleMIDelete(i) {
        this.props.dispatch (removeSelection('selectionMust', i))
    }

    handleMIAddition(tag) {
        this.props.dispatch (addSelection ('selectionMust', tag.text, 0))
    }

    handleMEDelete(i) {
        this.props.dispatch (removeSelection('selectionExclude', i))
    }

    handleMEAddition(tag) {
        this.props.dispatch (addSelection ('selectionExclude', tag.text, 0))
    }

    filterHasValues (uF, platformID) {
        var that = this
        var hasValues = false
        Object.keys(that.props.params.platformsStructure[platformID].catStructureFlat[uF]).forEach (function (element) { 
            hasValues = hasValues || that.checkBoxFilterValue('cb' + uF + '_' + element, platformID)
        });
        return hasValues
    }



    handleCBChange (id, platformID){
		var cfilters = this.props.query.changedFilters
        if (!(platformID in cfilters))
            cfilters[platformID] = {}

        var nval = !this.checkBoxFilterValue(id, platformID)
        cfilters[platformID][id] = nval
        var prefix = id.substring(2, id.indexOf ("_"))
        var realId = id.substring(id.indexOf ("_") + 1)
        if (this.props.projects && this.props.projects.company && this.props.projects.company.catStructureFlat)
            for (var cf of Object.keys(this.props.projects.company.catStructureFlat[prefix]))
                if (cf.startsWith (realId + '~'))
                    cfilters[platformID]["cb" + prefix + '_' + cf] = nval
        
        this.props.dispatch (setChangedFilters(cfilters))
    }

    cleanDateStringToFormatted (cds) {
        if (cds.length < 4)
            return cds
        if (cds.length < 6)
            return cds.substring(0,4) + '-' + cds.substring (4)
        return cds.substring(0,4) + '-' + cds.substring (4,6) + '-' + cds.substring(6)
    }

    cleanDateToTimestamp (cds, isBegin) {
        if (cds.length < 4)
            return null
        
        var cy = parseInt(cds.substring(0,4))
        if ((cds.length < 6) && (!isBegin))
            cy += 1
        
        var cm = 0
        if (cds.length >= 6)
        {
            cm = parseInt(cds.substring(4,6)) - 1
            if ((cds.length < 8) && (!isBegin))
                cm += 1    
            while (cm >= 12)
            {
                cy += 1
                cm -= 12
            }
        }

        var cd = 1
        if (cds.length === 8)
            cd = parseInt(cds.substring(6,8))
        var cdate = new Date(Date.UTC(cy, cm, cd, 0, 0, 0, 0))
        return cdate.getTime()
    }

    timestampToCleanDate (ts) {    
        if (!ts)
            return ""
        var d = new Date(ts);
        return d.getFullYear() +  ("0"+(d.getMonth()+1)).slice(-2) +  ("0" + d.getDate()).slice(-2)
    }
    
    handleDateInputChange (e, dateType, isBegin=true) {
        var nval = e.target.value.replace(/\D/g,'').substring(0,8)
        if ((e.target.value.length < this.cleanDateStringToFormatted(this.state.cfilters[e.target.name]).length) && (nval === this.state.cfilters[e.target.name]))
            nval = nval.substring (0, nval.length-1)
        
        if (nval !== this.state.cfilters[e.target.name])
        {
            var nvalTS = this.cleanDateToTimestamp (nval, isBegin)
            var cParams = dateType === 'cdate' ? {minCDate:this.props.query.minCDate, maxCDate:this.props.query.maxCDate, permitEmptyCDate:this.props.query.permitEmptyCDate} : {minDate:this.props.query.minDate, maxDate:this.props.query.maxDate, permitEmptyDate:this.props.query.permitEmptyDate}
            if (nvalTS !== cParams[e.target.name])
            {
                cParams[e.target.name] = nvalTS
                if (dateType === 'cdate')
                    this.props.dispatch (setCDatesFilter(cParams))   
                else 
                    this.props.dispatch (setDatesFilter(cParams)) 
            }
            this.setState ({cfilters: Object.assign({}, this.state.cfilters, {[e.target.name]:nval, [e.target.name + "TS"]:nvalTS})})
            
        }
        
    }

    sameTSs (ts1, ts2) {
        if ((ts1 === null) || (ts1 === undefined))
            ts1 = -1
        if ((ts2 === null) || (ts2 === undefined))
            ts2 = -1
        return ts1 === ts2
    }

    syncDateValues (prevState) {
        var ts = Object.assign ({}, this.state.cfilters, {})
        var wereChanges = false

        var params = ["minDate", "maxDate", "minCDate", "maxCDate"]
        for (const dp of params)
         {
            if (!this.sameTSs (this.state.cfilters[dp + "TS"], this.props.query[dp]) && this.sameTSs (this.state.cfilters[dp + "TS"], prevState.cfilters[dp + "TS"]))
            {
                ts[dp] = this.timestampToCleanDate(this.props.query[dp])    
                ts[dp + "TS"] = this.props.query[dp]
                wereChanges = true
            }
        }
        if (wereChanges)
            this.setState ({cfilters:ts})
        
    }

	checkBoxFilterValue (id, platformID){
        if ((platformID in this.props.query.changedFilters) && (id in this.props.query.changedFilters[platformID]))
			return this.props.query.changedFilters[platformID][id]
		return true
	}

    setSubfilters (platformID, uF, val) {
        var cfilters = this.props.query.changedFilters    
        if (!(platformID in cfilters))
            cfilters[platformID] = {}
        Object.keys(this.props.params.platformsStructure[platformID].catStructureFlat[uF]).forEach (function (element) {
            cfilters[platformID]['cb' + uF + '_' + element] = val
        });
        this.props.dispatch (setChangedFilters(cfilters))  
    }

    
    checkBoxFiltersSetRecursive (caption, identifier, elements, level, collapseID, platformID) {
        var that = this
        if (!elements)
            return null

        if (that.props.params.platformsStructure[platformID].catOrders[identifier] < 0)
            return null

        var upperFilters = Object.keys (elements)
        upperFilters = upperFilters.sort(function (a, b)
            {
                var x = that.props.params.platformsStructure[platformID].catNames[a]; var y = that.props.params.platformsStructure[platformID].catNames[b];
                var xo = that.props.params.platformsStructure[platformID].catOrders[a]; var yo = that.props.params.platformsStructure[platformID].catOrders[b];
                return ((xo < yo) ? -1 : (xo > yo) ? 1 : (x < y) ? -1 : ((x > y) ? 1 : 0))
            });
        
        var wereUnchecked = false
        var wereShown = 0
        var entities = upperFilters.map(function (element) {
            if (that.props.params.platformsStructure[platformID].catStructureFlat[identifier][element] === 0)
                return null
            var mID = "cb" + identifier + "_" + element
            var colID = "collapse" + mID
            wereUnchecked = wereUnchecked || !that.checkBoxFilterValue(mID, platformID)
            var sub = that.checkBoxFiltersSetRecursive (null, identifier, elements[element], level+1, colID, platformID)
            var children = sub['children']
            wereUnchecked = wereUnchecked || sub['wereUnchecked']
            wereShown += 1
            return (<div  key={"k" + mID}>
                <li className="filterItem" style={{paddingLeft: 10*level + 'px'}}>
                    {(sub['wereShown'] > 0) ? 
                        <button className="collapsePlusBtn" aria-expanded={that.state.expandedFilters[colID]} aria-controls={colID} onClick={(e)=>{ 
                            var nExp = Object.assign({}, that.state.expandedFilters, {})
                            if (that.state.expandedFilters[colID])
                                nExp [colID] = false
                            else
                                nExp [colID] = true
                            that.setState ({expandedFilters: nExp})
                         }}>{that.state.expandedFilters[colID] ? "-":"+"}</button>:null}
                    <label htmlFor={"cb" + element} className="cbcontainer" onClick={(e) => {e.stopPropagation(); that.handleCBChange (mID, platformID) }}>{that.props.params.platformsStructure[platformID].catNames[element]}
                        <input type="checkbox" id={mID} name={"cb" + identifier} value={element} checked={that.checkBoxFilterValue(mID, platformID)} readOnly={true}></input>
                        <span className="checkmark"></span>
                    </label>
              </li>{children}</div>);
        });
        
        if (caption)
        {
            var hasValues = that.filterHasValues (identifier, platformID)
            return [
                <Dropdown drop={"right"} key={"filterSet" + identifier} id={identifier + "-container"} className={"filterDropdown" + (wereUnchecked ? " nonTrivial":"")}>
                    <Dropdown.Toggle variant="light" className="">
                        <span className="weightRegular">{caption}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu flip={false} className="optionsMenu withShadow">
                        <ul>
                            <div className="cbcontainerAll"><li>
                                <label htmlFor={"cb" + identifier + "_all"} className="cbcontainer all" onClick={(e) => {e.stopPropagation(); that.setSubfilters (platformID, identifier, !hasValues) }}>All
                                    <input type="checkbox" name={"cb" + identifier + "_all"}  checked={hasValues} readOnly={true}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </li></div>
                            
                            {entities}
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>, wereUnchecked

            ];
        }
        else
            return {children:
                <Collapse key={"filterSet" + identifier} in={that.state.expandedFilters[collapseID]}>
                    <div id={collapseID} >
                        {entities}
                    </div>
                </Collapse>, wereUnchecked, wereShown};
    }

  compilePlatformConfigs ()
    {
        var pConfigs = {}
        var qLanguage = this.props.query.treatmentLanguage ? this.props.query.treatmentLanguage : this.props.company.defaultLanguage
    
        for (var pid of Object.keys(this.props.company.accessiblePlatforms))
        {
            if ((pid in this.props.query.unselectedPlatforms) && (this.props.query.unselectedPlatforms[pid]))
                continue
            if (qLanguage && this.props.company.langToPlatforms && this.props.company.langToPlatforms[qLanguage] && (!this.props.company.langToPlatforms[qLanguage].includes(this.props.company.accessiblePlatforms[pid].id)))
                continue

            if (pid in this.props.query.changedFilters)
                pConfigs[pid] = {"searchQuery": {"changedFilters":this.props.query.changedFilters[pid]}}
            else 
                pConfigs[pid] = {"searchQuery": {"changedFilters":{}}}
        }
        return pConfigs
    }

  render(show) {
    var that = this
    
    var MAX_QS_SUGGESTIONS = this.state.qsShowMore ? 10 : 0;
    var shownQSCards = 0
    
    if (this.props.qsdocs)
        var QSCards = this.props.qsdocs.map(function (d) {
            shownQSCards += 1
            if (shownQSCards >= MAX_QS_SUGGESTIONS)
                return null
            return <div key={"qsCard" + d.id} className="queryCard" onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language)); that.props.dispatch (onShowQueryForm(false)); }}>
                        <span>{d.caption}</span>
                    </div>
        })  

    

    var quickEditPositionStyle = {top:this.state.quickEditY, left:this.state.quickEditX}
    if (this.state.quickEditX > window.innerWidth / 2)
        quickEditPositionStyle = {top:this.state.quickEditY, right:window.innerWidth-200-this.state.quickEditX}

    var isMac = window.navigator.platform.toLowerCase().includes('mac')
    if (!this.props.query.treatmentLanguage && this.props.projects.company.defaultLanguage)
        that.props.dispatch(setQueryLanguage(this.props.projects.company.defaultLanguage))
    var qLanguage = this.props.query.treatmentLanguage ? this.props.query.treatmentLanguage : this.props.projects.company.defaultLanguage
    var qLanguageName = qLanguage
    var aLangs = this.props.projects.company.availableLangs ? this.props.projects.company.availableLangs.map (function (cLang) {
        if (cLang[0] === qLanguage)
            qLanguageName = cLang[1]
        return <li key={"qLang" + cLang[0]}>
            <label htmlFor={"qLang" + cLang[0]} className="radiocontainer" onClick={(e) => {that.props.dispatch(setQueryLanguage(cLang[0])) }}>{cLang[1]}
                <input type="radio" name="qLang" value={cLang[0]} id={"qLang" + cLang[0]}  checked={cLang[0]===qLanguage} readOnly={true}></input>
                <span className="checkmark"></span>
            </label>
        </li>
    }) : null
    
    


    var cbfilters = []
    var alteredFilters = 0
    var unselectedPlatforms = 0
    var totalPlatforms = 0
    var filtersBtnCaption = "Configure filters/sources"
    var filtersNonTrivial = false

    if (this.props.params.accessiblePlatforms)
    {
        cbfilters = Object.keys(this.props.params.accessiblePlatforms).map (function (platformID) 
        {
            var cPlatform = that.props.params.accessiblePlatforms[platformID]
            var inLanguage = ! (qLanguage && that.props.params.langToPlatforms && that.props.params.langToPlatforms[qLanguage] && (!that.props.params.langToPlatforms[qLanguage].includes(cPlatform.id)))
            var userUnselected = ((platformID in that.props.query.unselectedPlatforms) && (that.props.query.unselectedPlatforms[platformID]))
            if (userUnselected && inLanguage)
                unselectedPlatforms += 1
            if (inLanguage && !userUnselected)
                totalPlatforms += 1
            var cPlatformFilters = []
            if (that.props.params.platformsStructure && (platformID in that.props.params.platformsStructure))
            {
                var cParams = that.props.params.platformsStructure[platformID]
                var upperFilters = Object.keys (cParams.catStructure)
                upperFilters = upperFilters.sort(function (a, b)
                    {
                    var x = cParams.catNames[a]; var y = cParams.catNames[b];
                    var xo = cParams.catOrders[a]; var yo = cParams.catOrders[b];
                    return ((xo < yo) ? -1 : (xo > yo) ? 1 : (x < y) ? -1 : ((x > y) ? 1 : 0))
                    });
                for (var uFc in upperFilters)
                {
                    var uF = upperFilters[uFc]
                    
                    var cFilter = that.checkBoxFiltersSetRecursive (cParams.catNames[uF], uF, cParams.catStructure[uF], 0, null, platformID)
                    if (cFilter)
                    {
                        if (cFilter[1])
                            alteredFilters += 1
                        cPlatformFilters.push (cFilter[0])
                    }
                }
            }
            return <div className={"platformFilters" + (inLanguage ? "":" notInLanguage")} key={'platformFilters' + platformID}>
                <label htmlFor={'platformFiltersCaption' + platformID} className={"cbcontainer platformCaption" + (inLanguage ? "":" disabled")} onClick={(e) => {e.stopPropagation(); console.log (cPlatform, platformID); if (inLanguage) that.props.dispatch (setPlatformDisabled(platformID, userUnselected)); }}><h4>{cPlatform.name}</h4>
                    <input type="checkbox" checked={inLanguage && !userUnselected} readOnly={true} name={'platformFiltersCaption' + platformID} />
                    <span className="checkmark"></span>
                </label>
                
                {cPlatformFilters}
            </div>
        })
        if ((alteredFilters > 0) || (unselectedPlatforms > 0))
        {
            filtersBtnCaption = "" + totalPlatforms + " source"
            if ((totalPlatforms % 10 !== 1) || (totalPlatforms === 11))
                filtersBtnCaption += 's'
            if (alteredFilters > 0)
            {
                filtersBtnCaption += ' with ' + alteredFilters + ' filter'
                if ((alteredFilters % 10 !== 1) || (alteredFilters === 11))
                    filtersBtnCaption += 's'
            }
            filtersNonTrivial = true
        }
    }

    var datesRange = <div id={'datesRange-container'}>
            <div id="date-slider-container">
                    <h4>Document date</h4>
                    <div className="field">
                        <div className="filter control withFloatingLabel">
                            <input
                                className="input"
                                name="minDate"
                                required
                                onChange={(e)=>{that.handleDateInputChange(e, 'date');}}
                                value={this.cleanDateStringToFormatted (this.state.cfilters.minDate)}
                                autocomplete="off"
                            />
                            <span className="floating-label">From YYYY-MM-DD</span>
                        </div>
                        <div className="filter control withFloatingLabel">
                            <input
                                className="input"
                                name="maxDate"
                                required
                                onChange={(e)=>{that.handleDateInputChange(e, 'date', false);}}
                                value={this.cleanDateStringToFormatted (this.state.cfilters.maxDate)}
                                autocomplete="off"
                            />
                            <span className="floating-label">To YYYY-MM-DD</span>
                        </div>
                    </div>    
                    <div>
                        <input checked={this.props.query.permitEmptyDate} type="checkbox" id="cbNoneDate" name="cbNoneDate" onChange={e => {this.props.dispatch(setDatesFilter({minDate: this.props.query.minDate, maxDate: this.props.query.maxDate, permitEmptyDate: !this.props.query.permitEmptyDate}));}}/>
                        <label htmlFor="cbNoneDate">Include documents with no dates</label>
                    </div>

                    
                </div>
                <div id="cdate-slider-container" className="mt-3">
                    <h4>Document text contains date</h4>
                    <div className="field">
                        <div className="filter control withFloatingLabel">
                            <input
                                className="input"
                                name="minCDate"
                                required
                                onChange={(e)=>{that.handleDateInputChange(e, 'cdate');}}
                                value={this.cleanDateStringToFormatted (this.state.cfilters.minCDate)}
                                autocomplete="off"
                            />
                            <span className="floating-label">From YYYY-MM-DD</span>
                        </div>
                        <div className="filter control withFloatingLabel">
                            <input
                                className="input"
                                name="maxCDate"
                                required
                                onChange={(e)=>{that.handleDateInputChange(e, 'cdate', false);}}
                                value={this.cleanDateStringToFormatted (this.state.cfilters.maxCDate)}
                                autocomplete="off"
                            />
                            <span className="floating-label">To YYYY-MM-DD</span>
                        </div>
                    </div>    
                    <div>
                        <input checked={this.props.query.permitEmptyCDate} type="checkbox" id="cbNoneCDate" name="cbNoneCDate" onChange={e => {this.props.dispatch(setCDatesFilter({minCDate: this.props.query.minCDate, maxCDate: this.props.query.maxCDate, permitEmptyCDate: !this.props.query.permitEmptyCDate}));}}/>
                        <label htmlFor="cbNoneCDate">Include documents with no dates in text</label>
                    </div>
                </div>
        </div>
        
        
    
    return (<GlobalHotKeys  keyMap={this.hotKeysMap} handlers={this.hotKeysHandlers}>
      <div className={'queryForm' + (this.state.minimized ? ' minimized':'')} >
            { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                <Joyride
                    ref={this.joyrideRef}
                    tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                    styles={{options: globalConfig.JOYRIDE.options}}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    disableOverlay={true}
                    disableScrolling={true}
                    showSkipButton={true}
                    hideBackButton={true}
                    run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                    locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                    continuous={false}
                    stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                    callback = { data => {
                            const { action, type } = data;
                            if (action === ACTIONS.SKIP)
                                this.props.dispatch (setTutorialElements(null))
                            if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                            {
                                //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                var cEl = this.props.interface.tutorialActiveElements[this.constructorName]
                                if (cEl === 2)
                                {
                                    if (window.getSelection) {
                                        if (window.getSelection().empty) {  // Chrome
                                            window.getSelection().empty();
                                        } else if (window.getSelection().removeAllRanges) {  // Firefox
                                            window.getSelection().removeAllRanges();
                                        }
                                        } else if (document.selection) {  // IE?
                                        document.selection.empty();
                                        }
                                }
                                //if ((cEl === 2) && (shownQSCards === 0))
                                //    cEl = 3
                                this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][cEl].nextStepRule))
                            }
                            this.checkSelectionsPanelVisible ()
                        
                            
                            
                        }
                    }
                />
                :null
            }
                    {this.state.filtersPanelVisible ? 
                        <div className="filtersPanelContainer" onMouseDown={(e)=>{e.stopPropagation(); this.setState({filtersPanelVisible:false})}}>
                            <div className="filtersPanel" onMouseDown={(e)=>{e.stopPropagation();}}>
                                <h3>Filters</h3>
                                <Scrollbar noScrollX={true}>
                                    <Container fluid>
                                        <h4 className="section">Common filters</h4>
                                        { datesRange }
                                        <h4 className="section">Specific filters</h4>
                                        { cbfilters }
                                        <button className="lightBtn clearFiltersBtn ml-0" type="reset" onClick={() => { this.props.dispatch (setQuery(Object.assign ({}, that.props.query, {changedFilters:{}, unselectedPlatforms: {}, minDate: null, maxDate: null, permitEmptyDate: false, minCDate: null, maxCDate: null, permitEmptyCDate: false}))); that.setState ({canSaveWatch: true})}}>Clear filters</button>
                                    </Container>
                                </Scrollbar>
                                <div className="filtersFooter">
                                    <button onMouseDown={(e)=>{this.setState({filtersPanelVisible:false})}} className={"button is-info filtersCloseBtn lightBtn"}>Close</button>
                                    <button type="submit" onMouseDown={(e)=>{this.setState({filtersPanelVisible:false}); that.handleSubmit(e)}} className={"button is-info filtersSubmitBtn"}>Search<span className="kbHint">{isMac ? "Command":"Ctrl"}+Enter</span></button>
                                </div>
                            </div>
                        </div>:null                
                    }

                    <Container fluid className="stdPadded stdTopPadded">
                        <Row className="queryContainer mb-0">
                            <Col md={12}>
                                <button onClick={(e)=>{this.props.dispatch(saveWatch(this.props.projects.currentProject.id, this.props.query, this.compilePlatformConfigs()  )); this.setState({canSaveWatch:false})}} className={this.state.canSaveWatch ? "active addToWatchBtn":" addToWatchBtn"} disabled={!this.state.canSaveWatch} title="Add to watch"><BellImg/></button>
                                <button type="submit" onMouseDown={this.handleSubmit} className={"button is-info submitBtn"}><SearchImg/>Search<span className="kbHint">{isMac ? "Command":"Ctrl"}+Enter</span></button>
                                {(this.props.projects.company.availableLangs && this.props.projects.company.availableLangs.length > 1) ? 
                                    <Dropdown drop={"down"} key={"queryLang"} id={"queryLang-container"} className={"filterDropdown queryLang"}>
                                        <Dropdown.Toggle variant="light" className="">
                                            <span className="weightRegular">{qLanguageName}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu flip={false} className="optionsMenu withShadow">
                                            <h4>Search language <span className="qm">?<span className="withShadow">You can specify here in which language your request should be treated</span></span></h4>
                                            <ul>
                                                {aLangs}
                                            </ul>
                                            { config.get('localConfig').TRANSLATION_ENABLED ? 
                                            <div className="translateCBcont">
                                                    <label htmlFor="withTranslationCB" className="cbcontainer" onClick={(e) => {e.stopPropagation(); that.props.dispatch (setTranslationState(1-that.props.query.withTranslation)); }}>Use auto translation
                                                    <input type="checkbox" checked={that.props.query.withTranslation === 1} readOnly={true} name="withTranslationCB" />
                                                        <span className="checkmark"></span>
                                                    </label>
                                            </div>:null}

                                        </Dropdown.Menu>
                                    </Dropdown>: null
                                }

                                <div className={"taContainer" + ((this.props.projects.company.availableLangs && this.props.projects.company.availableLangs.length > 1) ? " withLanguage":null)}>
                                    <TextareaAutosize
                                        maxRows={10}
                                        autoFocus
                                        className="input"
                                        name="query"
                                        onChange={this.handleChange}
                                        onSelect={this.handleSelect}
                                        onFocus={(e)=>{this.setState ({minimized:false})}}
                                        //onBlur={(e)=>{console.log (e, e.target); this.setState ({minimized:true})}}
                                        value={this.state.currentQText}
                                        placeholder="Type or paste your search request here"
                                        required
                                        id="textareaID"
                                        onKeyDown = {(e) => { if (e.keyCode == 13 && e.shiftKey == false) {
                                            e.preventDefault();
                                            that.handleSubmit (e);
                                          } }}
                                    />
                                </div>


                                {this.state.selectionsPanelVisible ?

                                    <div className="quickEditPopup withShadow" style={quickEditPositionStyle}>
                                        <button className="button is-info selectionMustBtn" type="reset" onClick={(e) => { this.handleSelectionClick ('selectionMust')}}>Must include</button>
                                    </div>:null
                                }

                                
                            </Col>
                        </Row>
                        <Row className="configRow">
                            <Col md={12}  className="selectionsPanel mustPanel" onClick={(e) => { this.checkSelectionsPanelVisible (); }}>
                                <div className="queries">
                                    <label className="plabel mb-2">Must include <span className="qm">?<span className="withShadow">Each document displayed in the results must have exactly these selections. Select words to add one</span></span></label>
                                    <ReactTags tags={((this.props.query.selectionMust) && (this.props.query.selectionMust.length > 0)) ? (this.props.query.selectionMust.map(function (d, ind) { return {"id":"" + ind, "text":d}})) : []}
                                            handleDelete={this.handleMIDelete.bind(this)}
                                            handleAddition={this.handleMIAddition.bind(this)}
                                            delimiters={tagsDelimiters}
                                            allowDragDrop={false}
                                            placeholder="+ Add"
                                            allowDeleteFromEmptyInput={false}
                                            autofocus={false} />
                                    
                                </div>
                                <div className="queries">
                                    <label className="plabel mb-2">Must exclude <span className="qm">?<span className="withShadow">Each document displayed in the results can not have exactly these selections</span></span></label>
                                    <ReactTags tags={((this.props.query.selectionExclude) && (this.props.query.selectionExclude.length > 0)) ? (this.props.query.selectionExclude.map(function (d, ind) { return {"id":"" + ind, "text":d}})) : []}
                                            handleDelete={this.handleMEDelete.bind(this)}
                                            handleAddition={this.handleMEAddition.bind(this)}
                                            delimiters={tagsDelimiters}
                                            allowDragDrop={false}
                                            placeholder="+ Add"
                                            allowDeleteFromEmptyInput={false}
                                            autofocus={false} />
                                    
                                </div>
                                <div>
                                    <button className={"showFiltersButton lowPadding " + (filtersNonTrivial ? "":" lightBtn") } onClick={()=>{this.setState({filtersPanelVisible:true})}}>{filtersBtnCaption}</button>
                                    <button className="lightBtn clearBtn" type="reset" onClick={() => { this.checkSelectionsPanelVisible (); that.props.dispatch (setTranslationState(0)); this.props.dispatch (setQuery({query:""})); this.props.dispatch (findDocsByName("")); that.setState ({canSaveWatch: true})}}>Clear all</button>
                                </div>
                            </Col>
                        </Row>
                    

                        {(shownQSCards > 0) ? 
                            <Row className="suggestionsPanel mt-3">
                                {shownQSCards > 0 ? 
                                    <Col md={12} className="QSQueries">
                                        <div className="queries">
                                            <button onClick={(e) => { that.setState ({qsShowMore: !that.state.qsShowMore}); }} className="showMoreBtn realTransparent lowPadding">{that.state.qsShowMore ? "Hide" : "Show"}</button>
                                            <label className="plabel">Document name (only) based suggestions</label> 
                                            <div>
                                                {QSCards}
                                            </div>
                                        </div>
                                    </Col> : null}
                            </Row>
                            :null
                        }

                    </Container>  
            <GHelper hParams={{'QueryForm':0}}/>
            <MIHelper />
        
        </div>
        
      </GlobalHotKeys>
    );
  }


}

function mapStateToProps(state) {
  return {
    show: state.globalInterface.queryFormVisible,
    query: state.query,
    qsdocs:state.projects.qsdocs,
    projects:state.projects,
    interface: state.globalInterface,
    params: state.projects.company,
    company: state.projects.company
  }
}

export default connect(mapStateToProps)(QueryForm)
